import { React, useState} from 'react';
import { Grid } from '@mui/material';
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Footer from '../../../components/Footer/Footer';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { sendResponseForDRAccess } from '../../../services/UserManagementService'
import { sendDRResponseNotification } from '../../../services/AWSService';

const Requests = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const getListing = (listingId) => {
        for(let listingIndex in props.userPostedListings){
            let listing = {};
            if(props.userPostedListings[listingIndex]['listing-id'] === listingId){
                listing = props.userPostedListings[listingIndex];
            }
            return listing;
        }
    }

    const getDisplayName = (firstName, lastName, email) => {
        let displayName = "";
        if(!firstName || !!lastName){
            displayName = email;
        }else{
            displayName = firstName + " " + lastName;
        }
        return displayName;
    }

    const handleDrResponseStatus = async(buyerEmail, headline, listingId, sellerProfile, statusResponse) => {
        setIsLoading(true);
        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
            window.gtag("event", "button_click", {
              button: (statusResponse === "Approved") ? "approve_dr_access" : "reject_dr_access"
            });
          }
        const response = await sendResponseForDRAccess(buyerEmail, listingId, sellerProfile, statusResponse);
        await sendDRResponseNotification({
            'headline' : headline,
            'listingId': listingId,
            'status' : statusResponse,
            'email' : buyerEmail
        })
        props.setDrRequestsReceived(response['dr-requests-received']);
        setIsLoading(false);
    }

    const handleAvatar = (listingId, requestObj, buyerEmail) => {
        let listing = getListing(listingId);
        let postedByAvatar = null;
        let initials = null;

        // {!props.userAttributes.picture && !props.userProfileData?.avatar && <Avatar sx={{ bgcolor: "#0e9384"}} src="">{initials}</Avatar>}
        if(requestObj.avatar){
            postedByAvatar = `${window.env.REACT_APP_S3_AVATARS_URL}${requestObj.avatar}`;
        }else if(!requestObj.avatar && (requestObj['first-name'] || requestObj['last-name'])){
            let splitNameArray = (requestObj['first-name'] + " " + requestObj['last-name']).split(" ");
            if(splitNameArray.length > 1){
                initials = splitNameArray[0][0] + splitNameArray[splitNameArray.length - 1][0];
            }else{
                initials = splitNameArray[0][0];
            }
        }else{
            initials = buyerEmail[0].toUpperCase();
        }
        return(
            <Grid item xs={6} sx={{borderRadius: "10px", boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", display:"flex", backgroundColor:"white"}}>
                <Grid item xs={2} sx={{justifyContent:"center", display:"Flex",alignContent:"center", flexWrap:"wrap"}}>
                    {postedByAvatar && !initials && <Avatar src={postedByAvatar} imgProps={{referrerPolicy:"no-referrer"}}/>}
                    {!postedByAvatar && initials && <Avatar sx={{ bgcolor: "#0e9384"}} src="">{initials}</Avatar>}
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Link to={{ pathname: '/buyer-profile', search: `?email=${buyerEmail}`}} className='listed-by-link' sx={{padding:"0px"}}>{getDisplayName(requestObj['first-name'], requestObj['last-name'], buyerEmail)}</Link>
                    </Grid>
                    <Grid item xs={12}>
                        {
                        listing &&
                        <a className='listed-by-link' href={`/listingDetails?id=${listing['listing-id']}`} target="_blank" rel="noreferrer">{listing.headline}</a>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={5} sx={{justifyContent:"space-evenly", display:"Flex", alignContent:"center", flexWrap:"wrap"}}>
                    {       
                        requestObj.status === "Pending" &&
                        <>
                            <LoadingButton loading={isLoading} variant="contained" color="success" onClick={() => handleDrResponseStatus(buyerEmail, listing.headline, listingId, props.userProfileData, "Approved")}>Approve</LoadingButton>
                            <LoadingButton loading={isLoading} variant="contained" color="error" onClick={() => handleDrResponseStatus(buyerEmail, listing.headline, listingId, props.userProfileData, "Rejected")}>Reject</LoadingButton>
                        </>
                    }
                    {   
                        (requestObj.status === "Approved" || requestObj.status === "Rejected") &&
                        <>
                        <Button disabled={true} variant="contained" color="success">{requestObj.status}</Button>
                        </>
                    }
                </Grid>
                
            </Grid>
        )

    }

    const displayRequests = () => {
        const requestsByListings = Object.keys(props.drRequestsReceived);
        let requestsToDisplay = [];
        for(let listingKey in requestsByListings){
            const requestsByBuyer = Object.keys(props.drRequestsReceived[requestsByListings[listingKey]])
            for(let buyerEmailKey in requestsByBuyer){
                requestsToDisplay.push(
                    handleAvatar(requestsByListings[listingKey], props.drRequestsReceived[requestsByListings[listingKey]][requestsByBuyer[buyerEmailKey]], requestsByBuyer[buyerEmailKey])
                )
            }
        }
        return requestsToDisplay;
    }

    return(
        <>
          <Grid container direction="row" justifyContent="center" marginTop="8px">
            {displayRequests()}
          </Grid>
          <Footer />
        </>
      );
}

export default Requests;