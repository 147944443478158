import React, { useEffect, useState } from 'react';

const LogsPage = () => {
  const [logs, setLogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    try {
      const response = await fetch('/api/logs');
      if (!response.ok) {
        throw new Error('Failed to fetch logs');
      }
      const data = await response.text();
      const logEntries = data.split('-------------------------------').filter(entry => entry.trim());
      setLogs(logEntries.reverse()); // Reverse to show newest first
    } catch (err) {
      setError(err.message);
    }
  };

  const toggleCollapse = (index) => {
    setLogs(logs.map((log, i) => i === index ? { ...log, collapsed: !log.collapsed } : log));
  };

  const clearLogs = async () => {
    try {
      const response = await fetch('/api/clear-logs', { method: 'POST' });
      if (!response.ok) {
        throw new Error('Failed to clear logs');
      }
      setLogs([]);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h1>Application Logs</h1>
      <button onClick={clearLogs}>Clear Logs</button>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        logs.map((log, index) => {
          const lines = log.split('\n').filter(line => line.trim());
          const isCollapsed = logs[index]?.collapsed;
          return (
            <div
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? 'white' : '#f0f0f0',
                padding: '10px',
                marginBottom: '5px',
                border: '1px solid #ccc',
                cursor: 'pointer'
              }}
              onClick={() => toggleCollapse(index)}
            >
              <div>{lines[0]}</div>
              {!isCollapsed && (
                <div style={{ marginTop: '5px' }}>
                  {lines.slice(1).map((line, i) => (
                    <div key={i}>{line}</div>
                  ))}
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default LogsPage; 