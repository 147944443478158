export const constructOpenSearchRequest = (filterMap, brokeredListingsEmail="") => {
    let openSearchRequest = {
        "from": filterMap.from,
        "size": 100,
        "query":{
            "bool": {
                "must": [{"match": {"status" : "available"}}],
                "must_not" : [],
                "filter": [
                  {
                    "range" : {
                      "price": {
                          "gte" : filterMap.lowest_price,
                          "lte" : filterMap.highest_price
                      }
                    }
                  },
                  {
                    "range" : {
                      "cash-flow": {
                          "gte" : filterMap.lowest_cash_flow,
                          "lte" : filterMap.highest_cash_flow
                      }
                    }
                  },
                  {
                    "range" : {
                      "gross-revenue": {
                          "gte" : filterMap.lowest_revenue,
                          "lte" : filterMap.highest_revenue,
                      }
                    }
                  }
                ]
            }
        },
        "sort": {}
    };

    //handle onlySelfBrokeredListings
    if(brokeredListingsEmail){
      openSearchRequest.query.bool.must.push({"match": {"posted-by" : brokeredListingsEmail}});
    }

    //handle deal sourcing
    if(filterMap.aggregated && !filterMap.direct){
      openSearchRequest.query.bool.must.push({"match": {"posted-by" : "OneDeal"}});
    }else if(!filterMap.aggregated && filterMap.direct){
      openSearchRequest.query.bool.must_not.push({"match": {"posted-by" : "OneDeal"}});
    }

    //handle sorting
    if(filterMap.sort_by !== "Keywords"){
        switch(filterMap.sort_by){
            case 'Oldest':
                openSearchRequest.sort = {"request-time": {"order": "asc", "unmapped_type" : "long"}}; 
                break;
            case 'Highest Price':
                openSearchRequest.sort = {"price": {"order": "desc"}};
                break;
            case 'Lowest Price':
                openSearchRequest.sort = {"price": {"order": "asc"}};
                break;
            case 'Highest Cash Flow':
                openSearchRequest.sort = {"cash-flow": {"order": "desc"}};
                break;
            case 'Lowest Cash Flow':
                openSearchRequest.sort = {"cash-flow": {"order": "asc"}};
                break;
            case 'Highest Revenue':
                openSearchRequest.sort = {"gross-revenue": {"order": "desc"}};
                break;
            case 'Lowest Revenue':
                openSearchRequest.sort = {"gross-revenue": {"order": "asc"}};
                break;
            default:
                openSearchRequest.sort = {"request-time": {"order": "desc", "unmapped_type" : "long"}}; 
        }
    }

    //handle industries
    if(Object.keys(filterMap.industries).length){
        openSearchRequest.query.bool.must.push({"terms": {"industry.keyword": Object.keys(filterMap.industries)}});
    }

    //handle location
    if(filterMap.location){
        const parseLocation = filterMap.location.split(',');
        let location = "";
        
        if(parseLocation.length > 1){
          location = parseLocation[1] + "," + parseLocation[0];
        }else if(parseLocation.length === 1){
          location = parseLocation[0];
        }
        openSearchRequest.query.bool.must.push({"match_phrase": {"location": {"query": location}}});
    }

    //handle search
    if(filterMap.search_phrase){
        openSearchRequest.query.bool.must.push({"multi_match": {"query": filterMap.search_phrase,"fields": ["headline^4", "description^2"]}});
    }
    return openSearchRequest;
}

export const shortNumber = (numberToShorten) => {
    let shortenedNumber = numberToShorten.toString();
    if(numberToShorten >= 1000000){
        shortenedNumber = (numberToShorten / 1000000).toFixed(1) + "M";
    }else if(numberToShorten < 1000000 && numberToShorten > 999){
        shortenedNumber = (numberToShorten / 1000).toFixed(1) + "k";
    }
    return shortenedNumber.replace('.0','');
};

export const threeRecentBusinesses = () => {
    return {
        "from": 0,
        "size": 3,
        "query": {
            "bool": {
              "must": [
                    {
                      "term": {
                        "isFeatured.keyword": "Y"
                      }
                    }
                ]
            }
        },
        "sort": {
          "request-time": {
            "order": "asc", 
            "unmapped_type" : "long"
          }
        }
      }
}