import { React, useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Box, Button, Grid } from '@mui/material';
import NavBar from '../../components/NavBar/NavBar';
import { SnackBar } from '../../components/SnackBar/SnackBar';
import styled from 'styled-components';
import { retrieveUserAttributes, retrieveUserProfile, updateUserProfile, getS3Docs, sendRequestForDRAccess} from '../../services/UserManagementService';
import { listingDetailsById, listingStatistics, retrieveListedByProfileData, getDataRoomDocURL} from '../../services/ListingManagementService';
import { requestInfo, sendDRRequestNotification } from '../../services/AWSService';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Footer from '../../components/Footer/Footer';
import '../../pages/Landing/App.scss';
import './ListingDetails.css';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import SignUpModal from './SignUpModal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import { getDropboxSignUrl } from '../../services/DropboxSignService';
import HelloSign from 'hellosign-embedded';
import blurredDataRoom from '../../assets/blurred-data-room.png'
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
import { MuiTelInput } from 'mui-tel-input'

const Titleh1 = styled.h1`
  @media (max-width: 767px) {
    font-size: 5vw;
  }
  @media (min-width: 768px) {
    font-size: 3vw;
  }
`;

const DividerStyle = {
  "@media(max-Width: 768px)": {
    display: "none",
  },
};

const Location = styled.span`
  line-height: 0px;
  font-size: 13px;
  color: grey;
`;

const ListedBy = styled.p`
  font-weight: bold;
  font-size: 13px;
  color: grey;
  text-align: left;
`;

const DetailedInfo = styled.span`
  font-size: 13px;
  color: black;
  float: right;
`;

const StatisticTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: grey;
`;

const ListingDetails = () => {
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const [listingDetails, setListingDetails] = useState();
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [postedByData, setPostedByData] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [displayRequestInfoForm, setDisplayRequestInfoForm] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const [moreInfoParams, setMoreInfoParams] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [dataroomDocs, setDataRoomDocs] = useState();
  const [invalidMap, setInvalidMap] = useState({});
  const navigate = useNavigate()
    
  const handleGoogleEvent = (buttonName) => {
    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      const gtagParamMap = {
        button: buttonName,
      };
      if (userProfileData) {
        gtagParamMap.user_email = userProfileData.email;
      }
      window.gtag("event", "button_click", gtagParamMap);
    }
  };

  const handleValidateRequest = () => {
    let validationMap = {};
    let isValid = true;
    const requiredKeys = Object.keys(moreInfoParams);
    for (let x = 0; x < requiredKeys.length; x++) {
      if (!moreInfoParams[requiredKeys[x]]) {
        validationMap[requiredKeys[x]] = true;
        isValid = false;
      } else {
        validationMap[requiredKeys[x]] = false;
      }
    }
    setInvalidMap(validationMap);
    return isValid;
  };

  const getUserAttributes = async () => {
    retrieveUserAttributes().then((data) => {
      if (data.errorMessage) {
        setErrorMessage(data.errorMessage);
      } else {
        setUserAttributes(data.result);
        if (
          listingDetails &&
          (!data.result.email ||
            data.result.email !== listingDetails["posted-by-email"])
        ) {
          listingStatistics({
            listing: listingDetails,
            action: "click",
            "user-email": data.result.email,
          });
        }
      }
    });
  };

  const getUserProfile = async () => {
    retrieveUserProfile().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserProfileData(response);
        setMoreInfoParams({
          ...moreInfoParams,
          email: response.email,
          firstName: response["first-name"],
          lastName: response["last-name"],
          phone: response?.phone,
        });
      }
    });
  };

  const getPostedByListing = async (_listingDetails) => {
    let initials = "";
    let postedByAvatar = "";
    await retrieveListedByProfileData(_listingDetails["posted-by-email"]).then(
      (response) => {
        postedByAvatar = response?.avatar;
      }
    );
    if (postedByAvatar) {
      postedByAvatar = `${window.env.REACT_APP_S3_AVATARS_URL}${postedByAvatar}`;
    } else if (!postedByAvatar && _listingDetails["posted-by-picture"]) {
      postedByAvatar = _listingDetails["posted-by-picture"];
    } else {
      let splitNameArray = _listingDetails["posted-by-name"].split(" ");
      if (splitNameArray.length > 1) {
        initials =
          splitNameArray[0][0] + splitNameArray[splitNameArray.length - 1][0];
      } else {
        initials = splitNameArray[0][0];
      }
    }
    setPostedByData({ initials: initials, postedByAvatar: postedByAvatar });
  };

  const getDataRoomFiles = async (listingId) => {
    const results = await getS3Docs(
      "onedeal-broker-documents",
      `${listingDetails["posted-by-email"]}/datarooms/${listingId}/`
    );
    setDataRoomDocs(results);
  };

  const GetAvatar = () => {
    return (
      <>
        {postedByData?.postedByAvatar && !postedByData?.initials && (
          <Avatar
            src={postedByData?.postedByAvatar}
            imgProps={{ referrerPolicy: "no-referrer" }}
          />
        )}
        {!postedByData?.postedByAvatar && postedByData?.initials && (
          <Avatar sx={{ bgcolor: "#0e9384" }} src="">
            {postedByData?.initials}
          </Avatar>
        )}
      </>
    );
  };

  const getDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const postDate = new Date(dateString);
    return (
      months[postDate.getMonth()] +
      " " +
      postDate.getDate() +
      ", " +
      postDate.getFullYear()
    );
  };

  const daysOnOneDeal = (dateString) => {
    const days = Math.round(
      (Date.now() - new Date(dateString).getTime()) / (1000 * 3600 * 24)
    );
    return days < 1 ? "Less than 1" : days.toLocaleString("en-US");
  };

  const getLocation = (locationValue) => {
    if (locationValue.includes("Undisclosed")) {
      return "Undisclosed Location";
    }
    const splitLocation = locationValue.split(",");
    return `${splitLocation[1]}, ${splitLocation[0]}`;
  };

  const handleInfoUpdate = (event) => {
    let targetId = event.target.id;
    setMoreInfoParams({ ...moreInfoParams, [targetId]: event.target.value });

    if (targetId === "firstName") {
      targetId = "first-name";
    } else if (targetId === "lastName") {
      targetId = "last-name";
    }
    setUserProfileData({ ...userProfileData, [targetId]: event.target.value });
  };
  const handlePhoneUpdate = (event) => {
    setMoreInfoParams({ ...moreInfoParams, phone: event.replaceAll(" ", "") });
    setUserProfileData({
      ...userProfileData,
      phone: event.replaceAll(" ", ""),
    });
  };

  const submitRequestMoreInfo = () => {
    handleGoogleEvent("request_info");
    if (userAttributes && userProfileData) {
      if (handleValidateRequest()) {
        setIsLoading(true);
        requestInfo(
          moreInfoParams,
          listingDetails,
          window.location.href,
          userAttributes !== null
        )
          .then((response) => {
            if (response.message) {
              setErrorMessage(response.message);
            } else {
              setSuccessMessage("Message Received :) We'll respond ASAP!");
            }
            setIsLoading(false);
          })
          .then(() => {
            let requestedInfos = userProfileData["info-requests"]
              ? userProfileData["info-requests"]
              : {};
            requestedInfos[listingDetails["listing-id"]] = {
              status: { "requested-info": true, "received-nda": false },
            };
            let lastestProfileData = userProfileData;
            lastestProfileData["info-requests"] = requestedInfos;
            setUserProfileData({
              ...userProfileData,
              "info-requests": requestedInfos,
            });
            updateUserProfile(lastestProfileData);
          });
      } else {
        setDisplayRequestInfoForm(true);
      }
    } else {
      setDisplayModal(true);
    }
  };

  const submitRequestDataRoomAccess = async () => {
    handleGoogleEvent("request_dr_access");
    if (userAttributes && userProfileData) {
      // check if buyer has an NDA in his folder with same id and name that is tied to listing
      setIsLoading(true);
      let requiredNda = listingDetails["nda"].split("/");
      requiredNda = requiredNda[requiredNda.length - 1];
      const results = await getS3Docs(
        "onedeal-prequalification-documents",
        `${userProfileData.email}/signed-ndas/${listingDetails["listing-id"]}/${requiredNda}`
      );

      if (results.length) {
        const response = await sendRequestForDRAccess(
          userProfileData,
          listingDetails["posted-by-email"],
          listingDetails["listing-id"]
        );
        if (response.Error) {
          setErrorMessage(response.Error);
        } else {
          setSuccessMessage("Request Sent!");
          let displayName = "";
          if (!userProfileData["first-name"] && !userProfileData["last-name"]) {
            displayName = userProfileData.email;
          } else {
            displayName =
              userProfileData["first-name"] +
              " " +
              userProfileData["last-name"];
          }
          await sendDRRequestNotification({
            username: displayName,
            email: listingDetails["posted-by-email"],
            listingHeadline: listingDetails.headline,
          });
        }
      } else {
        const dropboxResponse = await getDropboxSignUrl(
          moreInfoParams.email,
          moreInfoParams.firstName,
          moreInfoParams.lastName,
          listingDetails["posted-by-name"],
          listingDetails["posted-by-email"],
          listingDetails.headline,
          listingDetails["listing-id"],
          requiredNda
        );
        const client = new HelloSign();

        client.on("sign", async (data) => {
          if (
            !window.location.host.includes("localhost") &&
            !window.location.host.includes("dev.onedeal.biz")
          ) {
            window.gtag("event", "button_click", {
              button: "nda_signed",
              user_email: moreInfoParams.email,
            });
          }
          const response = await sendRequestForDRAccess(
            userProfileData,
            listingDetails["posted-by-email"],
            listingDetails["listing-id"]
          );
          if (response.Error) {
            setErrorMessage(response.Error);
          } else {
            setSuccessMessage("Request Sent!");
            let displayName = "";
            if (
              !userProfileData["first-name"] &&
              !userProfileData["last-name"]
            ) {
              displayName = userProfileData.email;
            } else {
              displayName =
                userProfileData["first-name"] +
                " " +
                userProfileData["last-name"];
            }
            await sendDRRequestNotification({
              username: displayName,
              email: listingDetails["posted-by-email"],
              listingHeadline: listingDetails.headline,
            });
          }
        });

        client.open(dropboxResponse.embedded.signUrl, {
          clientId: window.env.REACT_APP_DROPBOX_CLIENT_ID,
          skipDomainVerification: false,
        });
      }
    } else {
      setDisplayModal(true);
    }
    setIsLoading(false);
  };

  const location = useLocation();

  const hasDataRoomAccess = () => {
    let dataRoomDisplay = "none";
    if (dataroomDocs && dataroomDocs.length) {
      dataRoomDisplay = "blurr";
    }

    if (
      userProfileData &&
      dataroomDocs &&
      dataroomDocs.length &&
      userProfileData["dr-requests-sent"] &&
      userProfileData["dr-requests-sent"][listingDetails["listing-id"]] &&
      userProfileData["dr-requests-sent"][listingDetails["listing-id"]] ===
        "Approved"
    ) {
      dataRoomDisplay = "displayDataRoom";
    }
    return dataRoomDisplay;
  };

  const getCityLocation = (location) => {
    let cityValue = "N/A";
    const splitLocation = listingDetails.location.split(",")[1];

    if (!location.includes("Undisclosed") && splitLocation !== "") {
      cityValue = splitLocation;
    }
    return cityValue;
  };
  useEffect(() => {
    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
    if (localStorage.getItem("authorized") && !userAttributes) {
      getUserAttributes();
    } else if (localStorage.getItem("authorized") && !userProfileData) {
      getUserProfile();
    } else if (!localStorage.getItem("authorized") && listingDetails) {
      listingStatistics({
        listing: listingDetails,
        action: "click",
        "user-email": null,
      });
    }
    if (!listingDetails) {
      const queryParams = new URLSearchParams(window.location.search);
      listingDetailsById(queryParams.get("id")).then((data) => {
        setListingDetails(data.Items[0]);
      });
    } else if (listingDetails) {
      getPostedByListing(listingDetails);
    }

    if (!dataroomDocs && listingDetails) {
      getDataRoomFiles(listingDetails["listing-id"]);
    }

    window.scrollTo(0, 0);
  }, [userAttributes, userProfileData, listingDetails, dataroomDocs]);

  return (
    <Box>
      <SetPageTitle title="Businesses Details" />
      <NavBar
        userAttributes={userAttributes}
        setUserAttributes={setUserAttributes}
        userProfileData={userProfileData}
      />
      {listingDetails && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          marginTop="8px"
          display="flex"
          marginBottom="35px"
        >
          {displayModal && (
            <SignUpModal
              displayModal={displayModal}
              setDisplayModal={setDisplayModal}
            />
          )}
          <Grid item xs={10}>
            <Titleh1>{listingDetails.headline}</Titleh1>
            <Location>{getLocation(listingDetails.location)}</Location>
          </Grid>
          <Grid
            item
            xs={10}
            md={5.99}
            sx={{ paddingRight: "10px", paddingTop: "10px" }}
          >
            <img
              src={`${window.env.REACT_APP_S3_URL}${listingDetails["s3-resource-url"]}`}
              alt="BusinessPhoto"
              style={{ width: "100%", borderRadius: "10px" }}
            />
            <h3 style={{ color: "#0e9384" }}>Description</h3>
            <p>{listingDetails.description}</p>
            <Divider orientation="horizontal" />

            <h3 style={{ color: "#0e9384" }}>Detailed Information</h3>
            <Grid item xs={12}>
              <p>
                Days on OneDeal:{" "}
                <DetailedInfo>
                  {daysOnOneDeal(listingDetails["request-time"])}
                </DetailedInfo>
              </p>
              <p>
                Address:
                <DetailedInfo>
                  {listingDetails.address ? listingDetails.address : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Apartment Number:
                <DetailedInfo>
                  {listingDetails.apartment ? listingDetails.apartment : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                City:
                <DetailedInfo>
                  {getCityLocation(listingDetails.location)}
                </DetailedInfo>
              </p>
              <p>
                State:
                <DetailedInfo>
                  {!listingDetails.location.includes("Undisclosed")
                    ? listingDetails.location.split(",")[0]
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                ZipCode:
                <DetailedInfo>
                  {listingDetails.zipcode ? listingDetails.zipcode : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Year Established:
                <DetailedInfo>
                  {listingDetails["year-established"]
                    ? listingDetails["year-established"]
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Listing Type: <DetailedInfo>{listingDetails.type}</DetailedInfo>
              </p>
              <p>
                Industry: <DetailedInfo>{listingDetails.industry}</DetailedInfo>
              </p>
              <p>
                Financing Available:{" "}
                <DetailedInfo>
                  {listingDetails.financing ? "Yes" : "No"}
                </DetailedInfo>
              </p>
              <p>
                Valuation Rationale:
                <p>
                  {listingDetails["valution-rationale"]
                    ? listingDetails["valution-rationale"]
                    : "N/A"}
                </p>
              </p>
              <p>
                Reason For Selling:
                <p>
                  {listingDetails["selling-reason"]
                    ? listingDetails["selling-reason"]
                    : "N/A"}
                </p>
              </p>
              <p>
                Competition Description:
                <p>
                  {listingDetails.competition
                    ? listingDetails.competition
                    : "N/A"}
                </p>
              </p>
              <p>
                Growth & Expansion:
                <p>{listingDetails.growth ? listingDetails.growth : "N/A"}</p>
              </p>
            </Grid>
            <Divider orientation="horizontal" />

            <h3 style={{ color: "#0e9384" }}>Financial Information</h3>
            <Grid item xs={12}>
              <p>
                Gross Revenue:
                <DetailedInfo>
                  {listingDetails["gross-revenue"] > 0
                    ? listingDetails["gross-revenue"].toLocaleString("en-US")
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Cash Flow:{" "}
                <DetailedInfo>
                  {listingDetails["cash-flow"] > 0
                    ? listingDetails["cash-flow"].toLocaleString("en-US")
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                EBITDA:
                <DetailedInfo>
                  {listingDetails.ebitda
                    ? listingDetails.ebitda.toLocaleString("en-US")
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Expenses:
                <DetailedInfo>
                  {listingDetails.expenses
                    ? listingDetails.expenses.toLocaleString("en-US")
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Net Income:
                <DetailedInfo>
                  {listingDetails["net-income"]
                    ? listingDetails["net-income"].toLocaleString("en-US")
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Inventory:
                <DetailedInfo>
                  {listingDetails.inventory
                    ? listingDetails.inventory.toLocaleString("en-US")
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Rent:
                <DetailedInfo>
                  {listingDetails.rent
                    ? listingDetails.rent.toLocaleString("en-US")
                    : "N/A"}
                </DetailedInfo>
              </p>
              <p>
                Status:{" "}
                <DetailedInfo>
                  {listingDetails.status.charAt(0).toUpperCase() +
                    listingDetails.status.slice(1)}
                </DetailedInfo>
              </p>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem sx={DividerStyle} />
          <Grid
            item
            xs={12}
            md={4}
            sx={{ padding: "10px", textAlign: "center", height: "fit-content" }}
          >
            <Box
              sx={{
                borderRadius: "4px",
                boxShadow: " 0 2px 4px -1px rgba(0,0,0,0.25)",
                backgroundColor: "white",
              }}
            >
              <Grid item xs={12} sx={{ display: "flex", padding: "10px" }}>
                <Grid item xs={2} sm={1.5}>
                  {postedByData && (
                    <GetAvatar listingDetails={listingDetails} />
                  )}
                </Grid>
                <Grid item xs={10} sm={10.5}>
                  <Grid item sx={{ textAlign: "initial" }}>
                    {listingDetails["posted-by-name"] !== "OneDeal" ? (
                      <Link
                        to={{
                          pathname: "/broker-profile",
                          search: `?email=${listingDetails["posted-by-email"]}`,
                        }}
                        state={{ email: listingDetails["posted-by-email"] }}
                        className="broker-link"
                      >
                        Listed By: {listingDetails["posted-by-name"]}
                      </Link>
                    ) : (
                      <Link to="#" className="broker-link">
                        Listed by: OneDeal
                      </Link>
                    )}
                  </Grid>
                  <Grid item>
                    <ListedBy>
                      {"Posted On: " + getDate(listingDetails["request-time"])}
                    </ListedBy>
                  </Grid>
                </Grid>
              </Grid>
              <Divider orientation="horizontal" />
              <Grid item xs={12}>
                <StatisticTitle>Asking Price</StatisticTitle>
                <h4 style={{ color: "#0e9384" }}>
                  {listingDetails.price > 0
                    ? "$" + listingDetails.price.toLocaleString("en-US")
                    : "N/A"}
                </h4>
              </Grid>

              {displayRequestInfoForm && (
                <Slide
                  direction="up"
                  in={displayRequestInfoForm}
                  container={containerRef.current}
                >
                  <Grid container direction="row" justifyContent="center">
                    <Grid item xs={6}>
                      <TextField
                        error={invalidMap.firstName}
                        required
                        id="firstName"
                        label="First Name"
                        value={moreInfoParams.firstName}
                        variant="outlined"
                        onChange={handleInfoUpdate}
                        style={{ width: "90%", paddingBottom: "10px" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={invalidMap.lastName}
                        required
                        id="lastName"
                        label="Last Name"
                        value={moreInfoParams.lastName}
                        variant="outlined"
                        onChange={handleInfoUpdate}
                        style={{ width: "90%", paddingBottom: "10px" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MuiTelInput
                        error={invalidMap.phone}
                        required
                        id="phone"
                        label="Phone Number"
                        value={moreInfoParams.phone}
                        variant="outlined"
                        autoComplete="tel"
                        onChange={handlePhoneUpdate}
                        style={{ width: "95%", marginBottom: "8px" }}
                        defaultCountry="US"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={invalidMap.email}
                        required
                        id="email"
                        label="Email Address"
                        variant="outlined"
                        onChange={handleInfoUpdate}
                        style={{ width: "95%", marginBottom: "8px" }}
                        value={moreInfoParams.email}
                        autoComplete="username"
                      />
                    </Grid>
                  </Grid>
                </Slide>
              )}

              {dataroomDocs && !dataroomDocs.length && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                  ref={containerRef}
                >
                  {(!userProfileData ||
                    userProfileData["info-requests"] === undefined ||
                    (userProfileData["info-requests"] &&
                      !userProfileData["info-requests"][
                        listingDetails["listing-id"]
                      ])) &&
                    listingDetails.url && (
                      <Button
                        href={listingDetails.url}
                        target="_blank"
                        sx={{
                          marginTop: "8px",
                          marginBottom: "10px",
                          backgroundColor: "#0e9384",
                          fontWeight: "600",
                          fontSize: "16px",
                          borderRadius: "8px",
                          width: "95%",
                          padding: " 12px 20px",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "white",
                            color: "#0e9384",
                          },
                          border: "1px solid #0e9384",
                        }}
                      >
                        Get More Information
                      </Button>
                    )}
                </Grid>
              )}
              {dataroomDocs && dataroomDocs.length > 0 && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                  ref={containerRef}
                >
                  {(!userProfileData ||
                    userProfileData["dr-requests-sent"] === undefined ||
                    (userProfileData["dr-requests-sent"] &&
                      !userProfileData["dr-requests-sent"][
                        listingDetails["listing-id"]
                      ])) && (
                    <LoadingButton
                      onClick={submitRequestDataRoomAccess}
                      loading={isLoading}
                      variant="contained"
                      sx={{
                        marginTop: "8px",
                        marginBottom: "10px",
                        backgroundColor: "#0e9384",
                        fontWeight: "600",
                        fontSize: "16px",
                        borderRadius: "8px",
                        width: "95%",
                        padding: " 12px 20px",
                        "&:hover": {
                          backgroundColor: "white",
                          color: "#0e9384",
                        },
                        border: "1px solid #0e9384",
                      }}
                    >
                      Request Access to Data Room
                    </LoadingButton>
                  )}
                  {userProfileData &&
                    userProfileData["dr-requests-sent"] &&
                    userProfileData["dr-requests-sent"][
                      listingDetails["listing-id"]
                    ] && (
                      <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        sx={{
                          marginTop: "8px",
                          marginBottom: "10px",
                          backgroundColor: "grey",
                          fontWeight: "600",
                          fontSize: "16px",
                          borderRadius: "8px",
                          width: "95%",
                          padding: " 12px 20px",
                          "&:hover": { backgroundColor: "grey" },
                          border: "1px solid #0e9384",
                        }}
                      >
                        Access To Data Room Requested!
                      </LoadingButton>
                    )}
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  href="https://www.smartbizloans.com/onedeal"
                  target="_blank"
                  rel="noreferrer"
                  style={{ width: "100%" }}
                >
                  <button className="listingDetails-btn">
                    Apply For Financing
                  </button>
                </a>
              </Grid>
              {!listingDetails.location.includes("Undisclosed") && (
                <iframe
                  title={listingDetails.headline}
                  width="95%"
                  height="350px"
                  style={{ border: "0", borderRadius: "10px" }}
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={
                    `https://www.google.com/maps/embed/v1/place?key=${window.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=` +
                    getLocation(listingDetails.location)
                  }
                ></iframe>
              )}
              {hasDataRoomAccess() === "displayDataRoom" && (
                <Grid item xs={12}>
                  <Typography
                    sx={{ mt: 4, mb: 2 }}
                    variant="h6"
                    component="div"
                  >
                    Data Room
                  </Typography>
                  <List dense={dense}>
                    {dataroomDocs.map((doc) => {
                      let keyArray = doc.Key.split("/");
                      if (keyArray[keyArray.length - 1]) {
                        return (
                          <ListItem
                            secondaryAction={
                              <>
                                <IconButton edge="end" aria-label="delete">
                                  <DownloadIcon
                                    onClick={async () => {
                                      handleGoogleEvent("download_dr_doc");
                                      window.open(
                                        await getDataRoomDocURL(doc.Key),
                                        "_blank"
                                      );
                                    }}
                                  />
                                </IconButton>
                              </>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <DescriptionIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${keyArray[keyArray.length - 1]}`}
                              secondary={secondary ? "Secondary text" : null}
                            />
                          </ListItem>
                        );
                      }
                    })}
                  </List>
                </Grid>
              )}
              {hasDataRoomAccess() === "blurr" && (
                <Grid item xs={12}>
                  <img src={blurredDataRoom} alt="data room" />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      {listingDetails && <Footer />}
      {(errorMessage || successMessage) && (
        <SnackBar
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </Box>
  );
};

export default ListingDetails;
