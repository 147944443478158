import React from "react";
import { HashLink } from "react-router-hash-link";
import logo from "../../assets/logo.png";

const data = [
  {
    title: "Product",
    submenu: [
      {
        subtitle: "Browse businesses",
        link: "/businesses-for-sale",
      },
      {
        subtitle: "Sell a business",
        link: "/sell-a-business",
      },
      // {
      // 	subtitle: "For brokers",
      // 	link: "/business-brokers",
      // },
    ],
  },
  {
    title: "Company",
    submenu: [
      {
        subtitle: "FAQ",
        link: "/frequently-asked-questions",
      },
      {
        subtitle: "Our Partners",
        link: "/DealScoutLandingPage",
      },
      {
        subtitle: "Terms of Service",
        link: "/terms-of-service",
      },
      {
        subtitle: "Privacy Policy",
        link: "/privacy-policy",
      },
    ],
  },
  {
    title: "Social",
    submenu: [
      {
        subtitle: "X",
        link: "https://x.com/onedeal_biz",
      },
      {
        subtitle: "LinkedIn",
        link: "https://www.linkedin.com/company/onedeal/about/",
      },
      {
        subtitle: "Contact Us",
        link: "mailto:info@onedeal.biz"
      }
    ],
  },
];

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-top">
          {data?.map(({ title, submenu }, i) => (
            <div className="footer-widget" key={i}>
              <h5>{title}</h5>
              <ul>
                {submenu?.map(({ subtitle, link }, i) => (
                  <li key={i}>
                    {title === "Social" ? (
                      <a href={link} target="_blank" rel="noreferrer">
                        {subtitle}
                      </a>
                    ) : (
                      <HashLink to={link + "#"}>{subtitle}</HashLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="footer-bottom">
          <HashLink to="/#" className="logo">
            <img src={logo} alt="" />
          </HashLink>
          <span>
            © {new Date().getFullYear()} OneDeal. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
