import { React, useState } from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Footer from '../../components/Footer/Footer';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DataRoomUploader from './DataRoomUploader';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

export const Financials = (props) => {
  
  const [invalidMap, setInvalidMap] = useState({});

  const listingInfoReqKeys = ['cash-flow'];
  const handleValidateListingInfo = () => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
        window.gtag("event", "button_click", {
          button: "post_listing_financials"
        });
      }
    if(props.dataRoomFiles.length){
        listingInfoReqKeys.push('nda');
    }
    let validationMap = {};
    let isValid = true;
    for(let x = 0; x< listingInfoReqKeys.length; x++){
      if(props.businessDetails[listingInfoReqKeys[x]] === null || props.businessDetails[listingInfoReqKeys[x]] === ""){
        validationMap[listingInfoReqKeys[x]] = true;
        isValid = false;
      }else{
        validationMap[listingInfoReqKeys[x]] = false;
      }
    }
    setInvalidMap(validationMap);
    
    if(isValid){
        props.handleSubmit();
    }
  }

  const setNDASelectMenu = (availableNDAs) => {
    let menuItems = []; 

    if(availableNDAs.length > 0){
        menuItems = availableNDAs.map((nda) => {
            return(
                <MenuItem value={nda.Key}>{nda.Key.split('/')[2]}</MenuItem>
            )
        });
    }else{
        menuItems.push(<MenuItem onClick={() => {localStorage.setItem('listingManagementTab', 3); window.open(`${window.env.REACT_APP_REDIRECT_URI}/myOneDeal/non-disclosure-agreements`)}} >Upload an NDA to your "My OneDeal"</MenuItem>);
    }
    return menuItems;
  }

    return(
        <>
        <Grid container direction="row" justifyContent="center" height="100%">
          <Grid item xs={11} sm={6} sx={{padding: "4px", mt:"8px", borderRadius: "4px", boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", backgroundColor:"white",  height:"fit-content", marginTop:"auto"}}>
            <h3>Financials</h3>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Gross Revenue</InputLabel>
                    <OutlinedInput
                        id="gross-revenue"
                        value={props.businessDetails['gross-revenue']}
                        onChange={props.handleNumberUpdates}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Gross Revenue"
                        style={{width:"100%"}}
                        type="number"
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Cash Flow</InputLabel>
                    <OutlinedInput
                        required
                        error={invalidMap['cash-flow']}
                        id="cash-flow"
                        value={props.businessDetails['cash-flow']}
                        onChange={props.handleNumberUpdates}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Cash Flow"
                        style={{width:"100%"}}
                        type="number"
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">EBITDA</InputLabel>
                    <OutlinedInput
                        id="ebitda"
                        value={props.businessDetails.ebitda}
                        onChange={props.handleNumberUpdates}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="EBITDA"
                        style={{width:"100%"}}
                        type="number"
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Expenses</InputLabel>
                    <OutlinedInput
                        id="expenses"
                        value={props.businessDetails.expenses}
                        onChange={props.handleNumberUpdates}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Expenses"
                        style={{width:"100%"}}
                        type="number"
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Net Income</InputLabel>
                    <OutlinedInput
                        id="net-income"
                        value={props.businessDetails['net-income']}
                        onChange={props.handleNumberUpdates}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Net Income"
                        style={{width:"100%"}}
                        type="number"
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Inventory</InputLabel>
                    <OutlinedInput
                        id="inventory"
                        value={props.businessDetails.inventory}
                        onChange={props.handleNumberUpdates}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Inventory"
                        style={{width:"100%"}}
                        type="number"
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                    <TextField
                        error={invalidMap['year-established']}
                        id="year-established"
                        label="Year Established"
                        type="number"
                        variant="outlined"
                        // autoComplete="address-level2"
                        value={props.businessDetails['year-established']}
                        onChange={props.handleNumberUpdates}
                        sx={{width:"100%"}}
                        />
                </Grid>
                <Grid item xs={11} sm={6}>
                    <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Rent</InputLabel>
                    <OutlinedInput
                        id="rent"
                        value={props.businessDetails.rent}
                        onChange={props.handleNumberUpdates}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Rent"
                        style={{width:"100%"}}
                        type="number"
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={11}>
                    <TextField
                    id="valution-rationale"
                    label={`Why is your business worth $${props.businessDetails.price}?`}
                    multiline
                    value={props.businessDetails['valution-rationale']}
                    onChange={props.handleFieldUpdate}
                    style={{width:"100%"}}
                    rows={2}
                    />
                </Grid>
                <Grid item xs={11}>
                    <TextField
                    id="selling-reason"
                    label="Why are you selling?"
                    multiline
                    value={props.businessDetails['selling-reason']}
                    onChange={props.handleFieldUpdate}
                    style={{width:"100%"}}
                    rows={2}
                    />
                </Grid>
                <Grid item xs={11}>
                    <TextField
                    id="competition"
                    label="Tell us about your competitors..."
                    multiline
                    value={props.businessDetails.competition}
                    onChange={props.handleFieldUpdate}
                    style={{width:"100%"}}
                    rows={2}
                    />
                </Grid>
                <Grid item xs={11}>
                    <TextField
                    id="growth"
                    label="What are the growth opportunities for your business?"
                    multiline
                    value={props.businessDetails.growth}
                    onChange={props.handleFieldUpdate}
                    style={{width:"100%"}}
                    rows={2}
                    />
                </Grid>
                {
                    props.availableNDAs &&
                    <Grid item xs={11} sx={{display:"flex"}}>
                        <Grid items xs={1} sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <IconButton onClick={() => props.getAvailableNDAs()}>
                                <RefreshIcon fontSize='large' sx={{border:"1px solid #0e9384", borderRadius:"100%", color:"#0e9384"}}/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Associated NDA</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.businessDetails.nda}
                                    label="Associated NDA"
                                    onChange={props.handleNDASelect}
                                    error={!props.dataRoomFiles.length ? false : invalidMap['nda']}
                                >
                                    {setNDASelectMenu(props.availableNDAs)} 
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
          </Grid>
          <DataRoomUploader dataRoomFiles={props.dataRoomFiles} setDataRoomFiles={props.setDataRoomFiles}/>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={11} sm={6}>
            <LoadingButton
                onClick={handleValidateListingInfo}
                loading={props.loading}
                variant="contained"
                style={{marginTop:"8px", float:"right",backgroundColor: "#0e9384", fontWeight:"bold", borderRadius:"10px"}}>
                Publish Listing
            </LoadingButton>
            <Button onClick={() => props.setValidListing(false)} variant="contained" sx={{backgroundColor: "#E5E4E2", color:"#0e9384", mt:"8px",mr:"5px", float:"right",borderRadius:"10px"}}>Previous</Button>
        </Grid>
      </Grid>
      </Grid>
       <Footer/>
       </>
    );
}