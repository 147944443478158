import React from "react";
import { HashLink } from 'react-router-hash-link';

const Cta = () => {
	return (
		<section className="cta-section section-gap">
			<div className="container">
				<div className="cta-wrapper text-white">
					<h2 className="title text-white">
						Interested in taking a leap into entrepreneurship?
					</h2>
					<div className="text">
						Join over 1,000 buyers already using OneDeal to find their
						perfect business
					</div>
					<div className="btn-wrapper">
						{/* <Link to="#" className="cmn-btn bg-white">
							Chat with the OneDeal team
						</Link> */}
						<a className='cmn-btn bg-white' href="https://calendly.com/anthony-onedeal/30min" target="_blank" rel="noreferrer">Chat with the OneDeal team</a>
						<HashLink to="/signup#" className="cmn-btn">
							Sign up
						</HashLink>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Cta;
