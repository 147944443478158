import { React, useState } from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import { SnackBar } from '../SnackBar/SnackBar';
import { registerUser } from '../../services/UserManagementService';
import { Link, useLocation } from 'react-router-dom';
import { GoogleLoginButton } from "react-social-login-buttons";
import './Registration.css'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { MuiTelInput } from 'mui-tel-input'


const RegisterFormField = {
    margin: "auto"
}

export const Register = (props) => {
    const search = useLocation().search;
    let isBrokerParam = new URLSearchParams(search).get('isBroker');
   
    // state management
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [signUpDetails, setSignUpDetails] = useState({
        "firstName" : "",
        "lastName" : "",
        "phoneNumber" : "",
        "email" : "",
        "password" : "",
        "extension" : "",
        "isBroker" : isBrokerParam === "true" ? true : false
    });

    const [invalidMap, setInvalidMap] = useState({});
    
    const handleValidateRequest = (withGoogle=false) => {
        const requiredFields = ["firstName", "lastName", "phoneNumber"];
        if(!withGoogle){
            requiredFields.push("email");
            requiredFields.push("password");
        }
        let validationMap = {};
        let isValid = true;
        // const requiredKeys = requiredFields;
        for(let x = 0; x< requiredFields.length; x++){
          if(!signUpDetails[requiredFields[x]]){
            validationMap[requiredFields[x]] = true;
            isValid = false;
          }else{
            validationMap[requiredFields[x]] = false;
          }
        }
        setInvalidMap(validationMap);
        return isValid;
      }

    // update registration details state
    const handleFieldUpdate = (event) => {
        setSignUpDetails({...signUpDetails, [event.target.id] : event.target.value});
        props.setHoldSignUpDetails({...signUpDetails, [event.target.id] : event.target.value});
    };

    const handlePhoneUpdate = (event) => {
        setSignUpDetails({...signUpDetails, phoneNumber : event});
        props.setHoldSignUpDetails({...signUpDetails, phoneNumber : event});
    }

    // reveal password
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };
    const handleSubmit = (registrationProps) => {
        if(handleValidateRequest()){
            registerUser(
                setLoading,
                registrationProps,
                setErrorMessage,
                props.setRegistrationResponse
            );
        }
    };
    const handleGoogleLogin = () => {
        if(handleValidateRequest(true)){
            localStorage.setItem("g_signup_first_name", signUpDetails.firstName);
            localStorage.setItem("g_signup_last_name", signUpDetails.lastName);
            localStorage.setItem("g_signup_phone_number", signUpDetails.phoneNumber);
            localStorage.setItem("g_signup_is_broker", signUpDetails.isBroker);
            if(signUpDetails.extension){
                localStorage.setItem("g_signup_extension", signUpDetails.extension);
            }
            window.location.href=`${window.env.REACT_APP_COGNITO_HOSTED_UI}oauth2/authorize?identity_provider=Google&redirect_uri=${window.env.REACT_APP_REDIRECT_URI}/signup&response_type=CODE&client_id=${window.env.REACT_APP_COGNITO_CLIENT_ID}&scope=aws.cognito.signin.user.admin profile openid email`;
        }
    }

    return(
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={11} sx={RegisterFormField}>
                <h3>Welcome Aboard!</h3>
                <p>Please enter your details</p>
                <Grid item sx={{display:"flex", justifyContent:"space-between"}}>
                    <Grid item xs={5.88}>
                        <TextField
                            required
                            error={invalidMap.firstName}
                            id="firstName"
                            label="First Name"
                            variant="filled"
                            onChange={handleFieldUpdate}
                            value={signUpDetails.firstName}
                            style={{width:"100%", marginBottom: "8px"}}
                        />
                    </Grid>
                    <Grid item xs={5.88}>
                        <TextField
                            required
                            error={invalidMap.lastName}
                            id="lastName"
                            label="Last Name"
                            variant="filled"
                            onChange={handleFieldUpdate}
                            value={signUpDetails.lastName}
                            style={{width:"100%", marginBottom: "8px"}}
                        />
                    </Grid>
                </Grid>
                <Grid item sx={{display:"flex", justifyContent:"space-between"}}>
                    <Grid item xs={8.75}>
                        <MuiTelInput
                            required
                            error={invalidMap.phoneNumber}
                            id="phoneNumber"
                            label="Phone Number"
                            variant="filled"
                            onChange={handlePhoneUpdate}
                            value={signUpDetails.phoneNumber}
                            style={{width:"100%", marginBottom: "8px"}}
                            defaultCountry='US'
                            // flagSize='medium'
                        />
                    </Grid>
                    <Grid item xs={2.75}>
                        <TextField
                            id="extension"
                            label="Ext"
                            variant="filled"
                            onChange={handleFieldUpdate}
                            value={signUpDetails.extension}
                            style={{width:"100%", marginBottom: "8px"}}
                        />
                    </Grid>
                </Grid>
                <TextField
                    required
                    error={invalidMap.email}
                    id="email"
                    label="Email Address"
                    variant="filled"
                    autoComplete="username"
                    onChange={handleFieldUpdate}
                    style={{width:"100%", marginBottom: "8px"}}
                />
                <FormControl sx={{marginBottom: "8px", width: '100%'  }} variant="filled">
                    <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                    <FilledInput
                        id="password"
                        autoComplete='new-password'
                        type={showPassword ? 'text' : 'password'}
                        value={signUpDetails.password}
                        onChange={handleFieldUpdate}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormGroup sx={{alignContent:"center"}}>
                        <FormControlLabel control={<Switch id="financing" defaultChecked={signUpDetails.isBroker} onChange={() => {
                            setSignUpDetails({...signUpDetails, isBroker: !signUpDetails.isBroker});
                            props.setHoldSignUpDetails({...signUpDetails, isBroker: !signUpDetails.isBroker});
                        }}/>} label="I'm a professional broker" />
                    </FormGroup>
                    <LoadingButton
                        onClick={() => handleSubmit(signUpDetails)}
                        loading={loading}
                        variant="contained"
                        style={{marginTop:"8px", marginBottom:"8px", backgroundColor: "#0e9384"}}
                    >
                    SignUp
                    </LoadingButton>
                    {
                        <div id="outerGoogleDiv" style={{marginBottom:"8px"}}>
                            <GoogleLoginButton style={{width:"100%", height:"36px", margin:"0px"}} onClick={handleGoogleLogin}>Sign up with Google</GoogleLoginButton>
                        </div>
                    }
                    <Link to="/login" id="alreadyHaveAccount">Already have an account? Login!</Link>
                </FormControl>
                {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
            </Grid>
        </Grid>
    )
}