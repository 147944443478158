import { React, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";
import { Bar, Close } from "../IconTheme/IconTheme";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/UserManagementService";
import SignUpType from "./SignUpType";

const DealScoutNavBar = (props, { bg }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState();
  const [user, setUser] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [signUpModal, setSignUpModal] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position < 1) {
      setSticky(false);
    } else {
      setSticky(true);
    }
  };
  window.addEventListener("scroll", handleScroll);

  const handleLogout = async () => {
    await logout(setErrorMessage, navigate);
    props.setUserAttributes(null);
  };

  const GetAvatar = () => {
    let initials = "";
    if (!props.userAttributes.picture && !props.userProfileData?.avatar) {
      let splitNameArray = props.userAttributes.name.split(" ");
      if (splitNameArray.length > 1) {
        initials =
          splitNameArray[0][0] + splitNameArray[splitNameArray.length - 1][0];
      } else {
        initials = splitNameArray[0][0];
      }
    }

    return (
      <>
        {/* 1. else if, user has props.userProfileData?.avatar display that */}

        {props.userProfileData?.avatar && (
          <Avatar
            src={`${window.env.REACT_APP_S3_AVATARS_URL}${props.userProfileData?.avatar}`}
            imgProps={{ referrerPolicy: "no-referrer" }}
          />
        )}

        {/* 2. if user has google pic, display that */}
        {!props.userProfileData?.avatar && props.userAttributes.picture && (
          <Avatar
            src={props.userAttributes.picture}
            imgProps={{ referrerPolicy: "no-referrer" }}
          />
        )}

        {/* 3. else display initials */}
        {!props.userAttributes.picture && !props.userProfileData?.avatar && (
          <Avatar sx={{ bgcolor: "#0e9384" }} src="">
            {initials}
          </Avatar>
        )}
      </>
    );
  };

  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const handleGoogleEvent = (buttonName) => {
    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      const gtagParamMap = {
        button: buttonName,
      };
      window.gtag("event", "button_click", gtagParamMap);
    }
  };
  return (
    <header
      className={`${bg ? "border-0" : ""} ${sticky ? " bg-white" : ""}`}
      style={{ background: bg ? bg : "var(--body)", zIndex: "999" }}
    >
      <div className="container">
        <div className="header-wrapper">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>
          <div className="bar ms-auto" onClick={() => setMenuOpen(!menuOpen)}>
            <Bar />
          </div>
          <div className={`menu-area  ${menuOpen ? "active" : ""}`}>
            <div className="close" onClick={() => setMenuOpen(!menuOpen)}>
              <Close />
            </div>
            {props.userAttributes && localStorage.getItem("authorized") && (
              <div className="userAvatarMobile">
                <div className="header-btns d-flex flex-wrap">
                  <IconButton onClick={handleOpenUserMenu}>
                    <GetAvatar />
                  </IconButton>
                </div>
              </div>
            )}
            <ul className="menu" onClick={() => setMenuOpen(!menuOpen)}>
              <li>
                <NavLink to="/deal-scout">Deal Scout</NavLink>
              </li>
              <li>
                <NavLink to="/businesses-for-sale">Brokered Deals</NavLink>
              </li>
              <li>
                <NavLink to="/deal-scout/buyers">For Buyers</NavLink>
              </li>
              {
                ((!props.userAttributes && !localStorage.getItem("authorized")) ||  
                (props.userProfileData && !('subscriptions' in props.userProfileData))) &&
                  <li>
                    <NavLink to="/deal-scout/brokers">For Brokers</NavLink>
                  </li>
              }
              {
                 ((!props.userAttributes && !localStorage.getItem("authorized")) ||  
                 (props.userProfileData && !('subscriptions' in props.userProfileData))) &&
                  <li>
                    <NavLink to="/deal-scout/checkout">Pricing</NavLink>
                  </li>
              }
              {
                 (props.userAttributes && localStorage.getItem("authorized")) && 
                 (props.userProfileData && ('subscriptions' in props.userProfileData)) &&
                  <li>
                    <NavLink to="/deal-scout/checkout">Get More Credits</NavLink>
                  </li>
              }

              {props.userAttributes && localStorage.getItem("authorized") && (
                <div className="userLinksNonMobile">
                  <li>
                    <NavLink to="/myOneDeal">My OneDeal</NavLink>
                  </li>
                  <li>
                    <NavLink to="/user-profile">Profile</NavLink>
                  </li>
                  <li onClick={handleLogout}>Logout</li>
                </div>
              )}
            </ul>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              {props.userAttributes && localStorage.getItem("authorized") && (
                <div className="userAvatarNonMobile">
                  <div className="header-btns d-flex flex-wrap">
                    <IconButton onClick={handleOpenUserMenu}>
                      <GetAvatar />
                    </IconButton>
                  </div>
                </div>
              )}
              {!props.userAttributes && (
                <div className="header-btns d-flex flex-wrap">
                  <Link
                    to="/login"
                    className="cmn-btn btn-outline"
                    onClick={() => setUser(!user)}
                  >
                    Log in
                  </Link>
                  <button
                    className="cmn-btn"
                    onClick={() => setSignUpModal(!signUpModal)}
                  >
                    Sign Up
                  </button>
                  <SignUpType
                    signUpModal={signUpModal}
                    setSignUpModal={setSignUpModal}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Menu
        sx={{
          mt: "45px",
          zIndex: "2000",
          "@media(max-Width: 991px)": {
            display: "none",
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key="myListings" onClick={handleCloseUserMenu}>
          <Link to="/myOneDeal">
            <Button variant="text">My OneDeal</Button>
          </Link>
        </MenuItem>
        {/* <MenuItem key="profile" onClick={handleCloseUserMenu}>
				<Link to="/user-profile">
					<Button variant="text">Profile</Button>
				</Link>
            </MenuItem> */}
        <MenuItem key="logout" onClick={handleCloseUserMenu}>
          <Button variant="text" onClick={handleLogout}>
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </header>
  );
};
export default DealScoutNavBar;
