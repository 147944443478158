import {SearchMap} from "../pages/DealScout/SearchMap";

type BusinessData = Record<string, any>[];

export const searchForBusinesses = async (searchPayload: SearchMap): Promise<BusinessData> => {

    try {
        const response = await fetch('/api/dealScout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(searchPayload),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Failed to fetch businesses. Status: ${response.status}`);
        }
    } catch (error) {
        throw error;
    }
};


export const upsertDealScoutSearch = async(savedSearchPayload) => {
    return await fetch('/api/dealScout/saved-searches', {
       method: 'POST',
       headers: {
           'Content-Type': 'application/json',
       },
       body: JSON.stringify(savedSearchPayload),
   }).then(res => res.json()).then((data) => {
       return data;
   });
 };

 export const upsertDealScoutList = async(savedListPayload) => {
     return await fetch('/api/dealScout/saved-lists', {
       method: 'POST',
       headers: {
           'Content-Type': 'application/json',
       },
       body: JSON.stringify(savedListPayload),
   }).then(res => res.json()).then((data) => {
       return data;
   });
 };

 export const upsertDealScoutCampaign = async(savedCampaignPayload) => {
     return await fetch('/api/dealScout/saved-campaigns', {
       method: 'POST',
       headers: {
           'Content-Type': 'application/json',
       },
       body: JSON.stringify(savedCampaignPayload),
   }).then(res => res.json()).then((data) => {
       return data;
   });
 };

 export const getDealScoutData = async() => {
     return await fetch('/api/dealScout/scout-objects', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      }
  }).then(res => res.json()).then((data) => {
      return data;
  });
};

export const initializeDealScout = async() => {
    return await fetch('/api/dealScout/initialize', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      }
  }).then(res => res.json()).then((data) => {
      return data;
  });
};

// export const dataAxle = async(searchPayload) => {
//   const response = await fetch('/api/dealScout/data-axle', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(searchPayload),
//   }).then(res => res.json()).then((data) => {
//     return data;
//   });
//   return response;
// };

// export const getDataAxleCategories = async() => {
//   const response = await fetch('/api/dealScout/data-axle-sit-codes', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   }).then(res => res.json()).then((data) => {
//     return data;
//   });
//   return response;
// };