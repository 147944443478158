import { React, useState } from "react";
import { Grid, Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { upsertDealScoutList } from "../../../services/DealScout";

// Example inline styles or MUI SX for the divider
const dividerStyle = {
  width: "100%",
  backgroundColor: "#f0f0f0",
  borderRadius: "2px",
  margin: "1rem 0",
};

const SearchResults = (props) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [displayNewList, setDisplayNewList] = useState(false);
  const [selectListDropDown, setSelectListDropDown] = useState("");
  const [currentList, setCurrentList] = useState({
    name: "",
    businesses: {},
    lastModified: Date.now(),
  });
  const [noListName, setNoListName] = useState(false);

  const handleListSelect = (event) => {
    const newListId = event.target.value;
    setSelectListDropDown(newListId);

    if (
        props.usersDealScouts &&
        props.usersDealScouts.scoutData &&
        props.usersDealScouts.scoutData.lists &&
        newListId in props.usersDealScouts.scoutData.lists
    ) {
      setCurrentList(props.usersDealScouts.scoutData.lists[newListId]);
    }
  };

  const handleListName = (event) => {
    setCurrentList({
      name: event.target.value,
      businesses: {},
      lastModified: Date.now(),
    });
  };

  /**
   * Example of how you might handle add/remove from list
   * if you still have a unique ID field. If not, create one
   * or remove this functionality.
   */
  const handleAddRemoveFromList = async (biz) => {
    if (!selectListDropDown) {
      setNoListName(true);
      setDisplayPopup(true);
      return;
    }

    let updatedBusinesses = { ...currentList.businesses };
    // Suppose we treat "name" as a fallback "key"
    // or add an "id" field to IBusinessRecord
    const bizKey = biz.name || `biz-${Math.random()}`;

    if (updatedBusinesses[bizKey]) {
      // remove
      delete updatedBusinesses[bizKey];
    } else {
      // add
      updatedBusinesses[bizKey] = biz;
    }

    const updatedListObj = {
      ...currentList,
      businesses: updatedBusinesses,
      lastModified: Date.now(),
    };
    setCurrentList(updatedListObj);

    // Persist changes to backend
    const response = await upsertDealScoutList({
      listId: selectListDropDown,
      listBody: updatedListObj,
    });

    // If needed, also update the parent state
    // e.g. props.setUsersDealScouts(...)
  };

  return (
      <div>
        {displayPopup && (
            <div className="popup-overlay">
              <div className="popup">
                <h3>Please select or create a list to start.</h3>
                <button
                    className="listingDetails-btn"
                    onClick={() => setDisplayPopup(false)}
                >
                  Close
                </button>
              </div>
            </div>
        )}

        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item xs={10}>
            <h1 style={{ color: "black" }}>
                Search Results {props.searchResults.length > 0 && 
                    `(${props.searchResults.length} found)`
                }
            </h1>
          </Grid>

          {/* Show loading indicator while results are streaming in */}
          {props.searchResults.length === 0 && (
              <Grid item xs={10}>
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                      <p>Searching... Results will appear as they are found</p>
                  </div>
              </Grid>
          )}

          {/* List Select or Create New List */}
          {!displayNewList && (
              <Grid item xs={5} sx={{ backgroundColor: "white", borderRadius: "10px" }}>
                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                  <InputLabel id="saved-list-select">Select A Saved List Or Create A New One</InputLabel>
                  <Select
                      id="saved-list-select"
                      label="Select A Saved List Or Create A New One"
                      value={selectListDropDown}
                      onChange={handleListSelect}
                      error={noListName}
                      sx={{ borderRadius: "24px" }}
                  >
                    <MenuItem value="" onClick={() => setDisplayNewList(true)}>
                      Create New List
                    </MenuItem>
                    {props.usersDealScouts &&
                        props.usersDealScouts.scoutData &&
                        props.usersDealScouts.scoutData.lists &&
                        Object.keys(props.usersDealScouts.scoutData.lists).map((key) => (
                            <MenuItem key={key} value={key}>
                              {props.usersDealScouts.scoutData.lists[key].name}
                            </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
          )}

          {/* Create New List */}
          {displayNewList && (
              <>
                <Grid item xs={10}>
                  <TextField
                      id="name"
                      label="Enter Your New List Name Here!"
                      variant="outlined"
                      onMouseLeave={async () => {
                        // auto-save new list
                        const response = await upsertDealScoutList({
                          listId: selectListDropDown,
                          listBody: currentList,
                        });
                        setDisplayNewList(false);
                        setSelectListDropDown(response["updated-list-id"]);
                        setCurrentList(
                            response.Attributes.lists[response["updated-list-id"]]
                        );
                        props.setUsersDealScouts({
                          scoutData: response.Attributes,
                          userExists: true,
                        });
                        setNoListName(false);
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": { borderRadius: "24px" },
                      }}
                      onChange={handleListName}
                      value={currentList.name}
                  />
                </Grid>
              </>
          )}

          {/* Display the Search Results */}
          {props.searchResults &&
              props.searchResults
                  .slice(props.selectedPageRange[0], props.selectedPageRange[1])
                  .map((biz, index) => {
                    // If you're still using infogroup_id, replace "bizKey" accordingly
                    const bizKey = biz.name || `biz-${index}`;
                    const existsInList = currentList.businesses[bizKey] ? true : false;

                    return (
                        <Grid item xs={12} key={bizKey} sx={{ marginTop: "1rem" }}>
                          <Divider sx={dividerStyle} />

                          {/* Example of "Card" or "Block" for each business */}
                          <div style={{ backgroundColor: "#fff", padding: "1rem", borderRadius: "8px" }}>
                            {/* Title & Add/Remove Button */}
                            <Grid container>
                              <Grid item xs={10}>
                                <h2 style={{ margin: 0 }}>{biz.name || "Unknown Name"}</h2>
                              </Grid>
                              <Grid item xs={2} textAlign="right">
                                <button
                                    onClick={() => handleAddRemoveFromList(biz)}
                                    className="listingDetails-btn"
                                    style={{ marginBottom: "0.5rem" }}
                                >
                                  {existsInList ? "Remove" : "Add"} to List
                                </button>
                              </Grid>
                            </Grid>

                            {/* Display Extended Fields from IBusinessRecord */}
                            <Grid container spacing={2}>
                              {/* Primary Address Fields */}
                              <Grid item xs={6}>
                                <p style={{ margin: 0 }}>
                                  <b>Address:</b> {biz.address || ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>City:</b> {biz.city || ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>State:</b> {biz.state || ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Postal Code:</b> {biz.postalCode || ""}
                                </p>
                              </Grid>

                              {/* More Fields */}
                              <Grid item xs={6}>
                                <p style={{ margin: 0 }}>
                                  <b>Phone:</b> {biz.phone || "N/A"}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Website:</b> {biz.website || ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Services:</b>{" "}
                                  {biz.services && biz.services.length
                                      ? biz.services.join(", ")
                                      : "N/A"}
                                </p>
                              </Grid>

                              {/* Additional Info */}
                              <Grid item xs={12}>
                                <p style={{ margin: 0 }}>
                                  <b>Location:</b> {biz.location || ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Description:</b> {biz.location_description || ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Latitude:</b> {biz.latitude ?? "N/A"}, <b>Longitude:</b>{" "}
                                  {biz.longitude ?? "N/A"}
                                </p>
                                <p style={{ margin: 0 }}>
                                  <b>Timestamp:</b> {biz.timestamp || ""}
                                </p>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                    );
                  })}
        </Grid>
      </div>
  );
};

export default SearchResults;