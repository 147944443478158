import { React } from 'react';
import { notifyOneDealTeam } from './AWSService';

export const getS3Docs = async (bucket_name,folder_name) => {
  const response = await fetch('/api/aws/get-s3-docs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "bucket" : bucket_name,
      "folder-name" : folder_name
    })
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
}

export const deleteBrokerFilesFromS3 = async (file_title, folder_name) => {
  const response = await fetch('/api/aws/delete-broker-docs', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "file-title" : file_title,
      "folder-name" : folder_name
    })
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
}

export const putBrokerDocument = async(file_content, file_type, file_title, folder_name, setErrorMessage) => {
  const response = await fetch('/api/user/broker-documents', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'file-content' : file_content,
      'file-type' : file_type,
      'file-title' : file_title,
      'folder-name' : folder_name
    }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }
  else{
    return response;
  }
}

export const putDataRoomDocument = async(file_content, file_type, file_title, folder_name, subfolder_name, setErrorMessage) => {
  const response = await fetch('/api/user/dataroom-documents', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'file-content' : file_content,
      'file-type' : file_type,
      'file-title' : file_title,
      'folder-name' : folder_name,
      'subfolder-name' : subfolder_name
    }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }
  else{
    return response;
  }
}

export const deleteFilesFromS3 = async (file_title, folder_name) => {
  const response = await fetch('/api/aws/delete-prequalify-docs', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "file-title" : file_title,
      "folder-name" : folder_name
    })
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
}

export const putPrequalificationDocument = async(file_content, file_type, file_title, folder_name, setErrorMessage) => {
  const response = await fetch('/api/user/prequalify-documents', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'file-content' : file_content,
      'file-type' : file_type,
      'file-title' : file_title,
      'folder-name' : folder_name
    }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }
  else{
    return response;
  }
}

export const updatePrequalificationSettings = async(updatePayload) => {
  const response = await fetch('/api/user/prequalification-settings', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body : JSON.stringify(updatePayload)
  }).then(res => res.json()).then((data) => {
    return data;
  });

  return response;
};

export const updateUserProfile = async(updatePayload) => {
  const response = await fetch('/api/user/profile', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body : JSON.stringify(updatePayload)
  }).then(res => res.json()).then((data) => {
    return data;
  });

  return response;
};

export const putAvatar = async(image_content, image_type, image_title, setErrorMessage, userEmail) => {
  const response = await fetch('/api/user/image', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'image-content' : image_content,
      'image-type' : image_type,
      'image-title' : image_title,
      'user-email' : userEmail
    }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }
  else{
    return response;
  }
}

export const emailSubscription = async(userData) => {
  const response = await fetch('/api/user/email-subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData)
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const getSubscriptionLink = async(userData) => {
  const response = await fetch('/api/user/subscription-link', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData)
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const lastLoggedIn = async(userData) => {
  const response = await fetch('/api/user/last-logged-in', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData)
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const registerUser = async(setLoading, registrationDetails, setErrorMessage, setRegistrationResponse) => {
    setLoading(true);
    registrationDetails.phoneNumber = registrationDetails.phoneNumber.replaceAll(' ', '');
    //record user signing up/loggin in with google
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: "signup",
        user_email: registrationDetails.email
      });
    }
  
    const response = await fetch('/api/user/register', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(registrationDetails),
        }).then(res => res.json()).then((data) => {
            return data;
        });
    if(response.errorMessage){
        setErrorMessage(response.errorMessage);
    }else{
        setRegistrationResponse(response);
        
        await lastLoggedIn({
          firstName : registrationDetails.firstName,
          lastName: registrationDetails.lastName,
          phoneNumber : registrationDetails.phoneNumber,
          extension : registrationDetails.extension,
          email : registrationDetails.email,
          isBroker : registrationDetails.isBroker
        });

        if(registrationDetails.isBroker){
          await notifyOneDealTeam({
            firstName : registrationDetails.firstName,
            lastName: registrationDetails.lastName,
            phone : registrationDetails.phoneNumber,
            email : registrationDetails.email,
          })
        }
    }
    setLoading(false);
  };

export const resendCode = async(setResendLoading, username, setErrorMessage, setResendResponse) => {
  setResendLoading(true);
  const response = await fetch('/api/user/resendConfirmationCode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"username":username}),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }else{
    setResendResponse(response);
  }
  setResendLoading(false);
};

export const authenticate = async(setLoading, loginDetails, setErrorMessage, navigate, loggedInAfterVerification=false) => {
  setLoading(true);
  if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
    window.gtag("event", "button_click", {
      button: "login",
      user_email: loginDetails.email
    });
  }
  const response = await fetch('/api/user/authenticate', {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginDetails),
      }).then(res => res.json()).then((data) => {
          return data;
      });
  if(response.errorMessage){
      setErrorMessage(response.errorMessage);
  }else{
      localStorage.setItem("authorized", true);
      if(!loggedInAfterVerification){
        const lastLoggedInresponse = await lastLoggedIn({"email": response.email});
        navigate('/businesses-for-sale', { state: lastLoggedInresponse['has-logged-in'] });
      }
      
  }
  setLoading(false);
  return response;  
};

export const verifyCode = async(setConfirmationLoading, signUpDetails, confirmationCode, setErrorMessage, navigate) => {
  setConfirmationLoading(true);
  let response = await fetch('/api/user/confirmation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"username":signUpDetails.email, "confirmationCode":confirmationCode}),
  }).then(res => res.json()).then((data) => {
    return data;
  })
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }else if(response.result === "SUCCESS"){
    response = await authenticate(
      setConfirmationLoading,
      signUpDetails,
      setErrorMessage,
      navigate,
      true
    )
    return response;
  }
  setConfirmationLoading(false);
};

export const googleAuthentication = async(authCode, type) => {
 
   const codeResponse = await fetch(`${window.env.REACT_APP_COGNITO_HOSTED_UI}oauth2/token`, {
      method: 'POST',
      headers:{
          'Content-Type': 'application/x-www-form-urlencoded'
      },    
      body:  new URLSearchParams({
          'grant_type': 'authorization_code',
          'code': authCode,
          'client_id': window.env.REACT_APP_COGNITO_CLIENT_ID,
          'redirect_uri': (type === "signup") ? window.env.REACT_APP_REDIRECT_URI+"/signup" : window.env.REACT_APP_REDIRECT_URI,
   }),
  }).then(res => res.json()).then((data) => {
      return data;
  });
  
  const userExistsResponse = await doesUserExist(codeResponse);
  if(type === "login" && !userExistsResponse.userExists){
    return "User not found";
  }

  const authResponse = await fetch('/api/user/googleAuthenticate', {
    method: 'POST',
    headers:{
      'Content-Type': 'application/json'
    },    
    body: JSON.stringify({credential : codeResponse}),
  }).then(res => res.json()).then((data) => {
      return data;
  });

  //record user signing up/loggin in with google
  if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
    window.gtag("event", "button_click", {
      button: (type === "signup") ? "google_signup" : "google_login",
      user_email: authResponse.email
    });
  }
  
  if(!authResponse.errorMessage){
    localStorage.setItem("authorized", true);
    let lastLoggedInResponse;
    let userSignUpDetails = {email : authResponse.email }
    if(localStorage.getItem("g_signup_first_name")){
      userSignUpDetails = {
        email: authResponse.email,
        firstName : localStorage.getItem("g_signup_first_name"),
        lastName: localStorage.getItem("g_signup_last_name"),
        phoneNumber : localStorage.getItem("g_signup_country_code") + localStorage.getItem("g_signup_phone_number"),
        isBroker : localStorage.getItem("g_signup_is_broker") === "false" ? false : true
      }
      if(localStorage.getItem("g_signup_extension")){
        userSignUpDetails.extension = localStorage.getItem("g_signup_extension");
      }
    }
    
    lastLoggedInResponse = await lastLoggedIn(userSignUpDetails);

    if(localStorage.getItem("g_signup_is_broker") === 'Y'){
      await notifyOneDealTeam({
        firstName : localStorage.getItem("g_signup_first_name"),
        lastName: localStorage.getItem("g_signup_last_name"),
        phone : localStorage.getItem("g_signup_country_code") + localStorage.getItem("g_signup_phone_number"),
        email : authResponse.email,
      })
    }

    localStorage.removeItem("g_signup_first_name");
    localStorage.removeItem("g_signup_last_name");
    localStorage.removeItem("g_signup_country_code");
    localStorage.removeItem("g_signup_phone_number");
    localStorage.removeItem("g_signup_is_broker");
    localStorage.removeItem("g_signup_extension");
    
    return lastLoggedInResponse;
  }
};

export const forgotPassword = async(setLoading, username, setErrorMessage, setResetPasswordResponse) => {
  setLoading(true);
  const response = await fetch('/api/user/forgotPassword', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"username":username}),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }
  else{
    setResetPasswordResponse(response);
  }
  setLoading(false);
};

export const forgotPasswordConfirmation = async(setLoading, username, verificationCode, newPassword, setErrorMessage, setResetResponse, navigate) => {
  setLoading(true);
  const response = await fetch('/api/user/forgotPasswordConfirmation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"username":username, "verificationCode":verificationCode, "newPassword":newPassword}),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }
  else{
    navigate('/login');
    setResetResponse(response);
  }
  setLoading(false);
};

export const retrieveUserAttributes = async() => {
  const response = await fetch('/api/user/attributes').then(res => res.json()).then((data) => {
          return data;
      });
  return response;
};

export const updateUserAttributes = async(setErrorMessage, attributeList, setLoading) => {
  setLoading(true);
  const response = await fetch('/api/user/attributes',
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({attributeList: attributeList}),
    }
  ).then(res => res.json()).then((data) => {
          return data;
      });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }
  setLoading(false)
};

export const logout = async(setErrorMessage, navigate) => {
  const response = await fetch('/api/user/logout').then(res => res.json()).then((data) => {
          return data;
      });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }else{
    localStorage.removeItem("authorized");
    localStorage.removeItem("filterSettings")
    navigate('/');
  }
};

export const retrieveUserProfile = async() => {
  
  const response = await fetch('/api/user/user-profile', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const modifyUserFavoriteListings = async(modifyFavoritesPayload) => {
  
  const response = await fetch('/api/user/favorites-listings', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(modifyFavoritesPayload),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const modifySavedSearches = async(savedSearchPayload) => {
 const response = await fetch('/api/user/saved-searches', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(savedSearchPayload),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const retrieveBrokerInfo = async(brokerEmail) => {
  
  const response = await fetch('/api/user/broker-info', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email : brokerEmail})
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const retrieveAllBrokers = async() => {
  
  const response = await fetch('/api/user/brokers', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const sendRequestForDRAccess = async(buyerProfile, sellerEmail, listingId) => {
  const response = await fetch('/api/user/request-dr-access', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'buyerProfile' : buyerProfile,
      'sellerEmail' : sellerEmail,
      'listingId' : listingId
    })
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const sendResponseForDRAccess = async(buyerEmail, listingId, sellerProfile, statusResponse) => {
  const response = await fetch('/api/user/respond-dr-access', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'buyerEmail' : buyerEmail,
      'listingId' : listingId,
      'sellerProfile' : sellerProfile,
      'statusResponse' : statusResponse
    })
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const retrieveBuyerInfo = async(buyerEmail) => {
  
  const response = await fetch('/api/user/buyer-info', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email : buyerEmail})
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const doesUserExist = async(credential) => {
  
  const response = await fetch('/api/user/does-user-exist', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({'credential' : credential})
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};