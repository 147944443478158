import React, {useEffect, useState} from "react";
import {Grid, InputAdornment} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {upsertDealScoutSearch} from "../../../services/DealScout";
import SearchIcon from "@mui/icons-material/Search";
import CategoryDisplay from "../CategoryDisplay";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import GeoLocation from "./GeoLocation";
import {SnackBar} from "../../../components/SnackBar/SnackBar";
import {updateUserProfile} from "../../../services/UserManagementService";
import LinearProgress from "@mui/joy/LinearProgress";

const Scouts = (props) => {
  // State management
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorEmployees, setErrorEmployees] = useState(false);
  const [errorRevenue, setErrorRevenue] = useState(false);
  const [displayNewScout, setDisplayNewScout] = useState(false);
  const [selectScoutDropDown, setSelectScoutDropDown] = useState("");
  const [locationError, setLocationError] = useState(false);
  const [isSearching, setIsSearching] = useState(false);


  const [searchMap, setSearchMap] = useState({
    name: "",
    keywords: "",
    city: "",
    state: "",
    country: "",
    location_description: "",
    mile_radius: 50,
    lowest_employees: "",
    highest_employees: "",
    lowest_estimated_sales_revenue: "",
    highest_estimated_sales_revenue: "",
    categories: [],
    limit: 10,
  });

  const handleGeoLocationChange = (description, coordinateMap) => {
    // Parse the description to extract city, state, and country
    const locationParts = description.split(',').map(part => part.trim());
    console.log("Parsed location:", locationParts);

    const city = locationParts[0] || "";
    const state = locationParts[1] || "";
    const country = locationParts[2] || "";

    // Debugging: Ensure the values are parsed correctly
    console.log("Parsed location:", { city, state, country });

    // Update the searchMap
    setSearchMap({
      ...searchMap,
      location_description: description,
      city,
      state,
      country,
    });
  };

  const getSearchResults = async (searchParams) => {
    if (!isBadRequest()) {
        try {
            // Clear previous results and set loading state
            props.setSearchResults([]);
            setIsSearching(true);
            
            // Create EventSource for streaming
            const eventSource = new EventSource(`/api/dealScout/stream?${new URLSearchParams(searchParams)}`);

            eventSource.onmessage = async (event) => {
                const record = JSON.parse(event.data);
                console.log('Received record:', record); // Debugging log
                props.setSearchResults(prev => [...prev, record]);

                // Update credits as results come in
                if (props.userProfileData["subscriptions"]["deal-scout"]["status"] !== "inactive") {
                    let currentUserProfile = { ...props.userProfileData };
                    currentUserProfile["subscriptions"]["deal-scout"]["credits-used"]++;
                    await updateUserProfile(currentUserProfile);
                    props.setUserProfileData(currentUserProfile);
                }
            };

            eventSource.onerror = (error) => {
                console.error('EventSource error:', error);
                eventSource.close();
                setIsSearching(false);
                setErrorMessage('Search stream failed');
            };

            // Handle completion
            eventSource.addEventListener('complete', () => {
                eventSource.close();
                setIsSearching(false);
            });

            // Switch to results view
            props.setDrawComponent("searchResults");

        } catch (error) {
            console.error('Error in search:', error);
            setIsSearching(false);
            setErrorMessage('Search failed');
        }
    }
  };

  const isBadRequest = () => {
    let foundError = false;
    console.log("search map", searchMap);
    if (!searchMap.city && !searchMap.state && !searchMap.country) {
      setErrorMessage("At least one of city, state, or country is required.");
      setLocationError(true);
      foundError = true;
    } else if (
        parseInt(searchMap.lowest_employees) &&
        parseInt(searchMap.highest_employees) &&
        parseInt(searchMap.lowest_employees) >= parseInt(searchMap.highest_employees)
    ) {
      setErrorMessage("Higest amount of employees but be less than the lowest amount.");
      setErrorEmployees(true);
      foundError = true;
    } else if (
        parseInt(searchMap.lowest_estimated_sales_revenue) &&
        parseInt(searchMap.highest_estimated_sales_revenue) &&
        parseInt(searchMap.lowest_estimated_sales_revenue) >=
        parseInt(searchMap.highest_estimated_sales_revenue)
    ) {
      setErrorMessage("Higest revenue but be less than the lowest revenue.");
      setErrorRevenue(true);
      foundError = true;
    }
    // Check user credits
    else if (
        props.userProfile &&
        props.userProfileData["subscriptions"]["deal-scout"]["credits-used"] +
        searchMap.limit >
        props.userProfileData["subscriptions"]["deal-scout"]["credit-limit"]
    ) {
      setErrorMessage("You don't have enough credits to complete this scout.");
      foundError = true;
    } else if (
        props.userProfileData["subscriptions"]["deal-scout"]["status"] === "inactive"
    ) {
      setErrorMessage("Please renew your subscription.");
      foundError = true;
    }
    return foundError;
  };

  // Transform PPP data to a format similar to the original dataset


  const transformBBBData = (bbbEntries) => {
    return bbbEntries.map((record) => {
      return {
        name: record.name || "",
        city: record.city || "",
        state: record.state || "",
        // If BBB doesn't supply exact employees, default to 0 or use your own logic
        employees: 0,
        // Same for revenue
        revenue: 0,
        // If you don't have businessType from BBB, you can default it
        businessType: "",
        // Use `postalCode` for zip
        zip: record.postalCode || "",
        // If BBB doesn't supply NAICS codes, you can default it
        naicscode: "",
      };
    });
  };

  // Handler functions
  const handleLimit = (event) => {
    const value = parseInt(event.target.value);
    if ((value >= 0 && value <= 400) || isNaN(value)) {
      setSearchMap({ ...searchMap, limit: value });
    }
  };

  const handleRevenueLowest = (event) => {
    const value = event.target.value;
    if (parseInt(value) >= 0 || !parseInt(value)) {
      setSearchMap({
        ...searchMap,
        lowest_estimated_sales_revenue: value,
      });
    }
  };

  const handleRevenueHighest = (event) => {
    const value = event.target.value;
    if (parseInt(value) >= 0 || !parseInt(value)) {
      setSearchMap({
        ...searchMap,
        highest_estimated_sales_revenue: value,
      });
    }
  };

  const handleEmployeeCountLowest = (event) => {
    const value = event.target.value;
    if (parseInt(value) >= 0 || !parseInt(value)) {
      setSearchMap({ ...searchMap, lowest_employees: value });
    }
  };

  const handleEmployeeCountHighest = (event) => {
    const value = event.target.value;
    if (parseInt(value) >= 0 || !parseInt(value)) {
      setSearchMap({ ...searchMap, highest_employees: value });
    }
  };

  const handleScoutSelect = (event) => {
    setSelectScoutDropDown(event.target.value);
    if (
        props.usersDealScouts &&
        props.usersDealScouts.scoutData &&
        props.usersDealScouts.scoutData.scouts &&
        event.target.value in props.usersDealScouts.scoutData.scouts
    ) {
      setSearchMap(props.usersDealScouts.scoutData.scouts[event.target.value]);
    }
  };

  const handleSearchParamChange = (event) => {
    const { id, value } = event.target;
    setSearchMap({ ...searchMap, [id]: value });
  };




  const getPercentageOfCredits = () => {
    let percent = 0;
    if (props.userProfileData["subscriptions"]["deal-scout"]["credits-used"]) {
      percent =
          (props.userProfileData["subscriptions"]["deal-scout"]["credits-used"] /
              props.userProfileData["subscriptions"]["deal-scout"]["credit-limit"]) *
          100;
    }
    percent = percent < 5 && percent > 0 ? 5 : percent;
    return percent;
  };

  useEffect(() => {}, [searchMap]);

  return (
      <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
      >
        <Grid item xs={5}>
          <h1 style={{ color: "black", marginTop: "3rem" }}>Deal Scout</h1>
        </Grid>
        <Grid item xs={5}>
          {props.userProfileData && (
              <LinearProgress
                  determinate
                  size="md"
                  variant="soft"
                  thickness={32}
                  value={getPercentageOfCredits()}
                  sx={{
                    backgroundColor: "#8DCAC3",
                    color: "#0e9384",
                    width: "100%",
                    marginTop: "3rem",
                  }}
              >
                <Typography
                    level="body3"
                    fontWeight="xl"
                    sx={{ mixBlendMode: "color-dodge", color: "white" }}
                >
                  {props.userProfileData["subscriptions"]["deal-scout"]["credit-limit"] -
                      props.userProfileData["subscriptions"]["deal-scout"]["credits-used"] +
                      " Credits Left"}
                </Typography>
              </LinearProgress>
          )}
        </Grid>
        {!displayNewScout && (
            <Grid item xs={5} sx={{ backgroundColor: "white", borderRadius: "10px" }}>
              <FormControl sx={{ minWidth: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Select A Saved Scout Or Create A New One
                </InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectScoutDropDown}
                    label="Select A Saved Scout Or Create A New One"
                    onChange={handleScoutSelect}
                    sx={{ borderRadius: "24px" }}
                >
                  <MenuItem
                      value=""
                      onClick={() => setDisplayNewScout(true)}
                  >
                    Create New Scout
                  </MenuItem>
                  {props.usersDealScouts &&
                      "scoutData" in props.usersDealScouts &&
                      Object.keys(props.usersDealScouts.scoutData.scouts).map((key) => {
                        return (
                            <MenuItem key={key} value={key}>
                              {props.usersDealScouts.scoutData.scouts[key].name}
                            </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>
        )}
        {displayNewScout && (
            <>
              <Grid item xs={5}>
                <TextField
                    id="name"
                    label="Enter Your Saved Search Name Here!"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": { borderRadius: "24px" },
                    }}
                    onChange={handleSearchParamChange}
                    value={searchMap.name}
                />
              </Grid>
            </>
        )}
        <Grid item xs={5}>
          <button
              onClick={async () =>
                  await upsertDealScoutSearch({
                    scoutId: selectScoutDropDown,
                    scoutBody: searchMap,
                  })
              }
              style={{ width: "100%" }}
              className="listingDetails-btn"
          >
            Save This Search
          </button>
        </Grid>
        <Grid item xs={5} sx={{ backgroundColor: "white", borderRadius: "24px" }}>
          <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Location</h3>
          <GeoLocation
              handleGeoLocationChange={handleGeoLocationChange}
              locationError={locationError}
              location={searchMap.location}
              location_description={searchMap.location_description}
          />
        </Grid>
        <Grid item xs={5}>
          <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Mile Radius</h3>
          <FormControl sx={{ width: "100%" }}>
            <RadioGroup
                row
                id="mile_radius"
                value={searchMap.mile_radius}
                onChange={(e) =>
                    setSearchMap({ ...searchMap, mile_radius: parseInt(e.target.value) })
                }
                sx={{ justifyContent: "space-evenly" }}
            >
              <FormControlLabel
                  value="50"
                  control={
                    <Radio sx={{ "&, &.Mui-checked": { color: "#0e9384" } }} />
                  }
                  label="50"
              />
              <FormControlLabel
                  value="100"
                  control={
                    <Radio sx={{ "&, &.Mui-checked": { color: "#0e9384" } }} />
                  }
                  label="100"
              />
              <FormControlLabel
                  value="150"
                  control={
                    <Radio sx={{ "&, &.Mui-checked": { color: "#0e9384" } }} />
                  }
                  label="250"
              />
              <FormControlLabel
                  value="200"
                  control={
                    <Radio sx={{ "&, &.Mui-checked": { color: "#0e9384" } }} />
                  }
                  label="500"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={5} sx={{ backgroundColor: "white", borderRadius: "10px" }}>
          <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Keywords</h3>
          <TextField
              id="keywords"
              label='"Laundromat", "HVAC", "Car Wash"'
              variant="outlined"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": { borderRadius: "24px" },
              }}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                ),
              }}
              onChange={handleSearchParamChange}
              value={searchMap.keywords}
          />
        </Grid>
        <Grid item xs={5}>
          <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Categories</h3>
          <CategoryDisplay searchMap={searchMap} setSearchMap={setSearchMap} />
        </Grid>
        <Grid item xs={5} sx={{ fontSize: "4vw", textAlign: "center" }}>
          <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Number of Employees</h3>
          <Grid item xs={12} display="Flex" justifyContent="space-evenly">
            <TextField
                id="employee-low"
                label="Min Employees"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleEmployeeCountLowest}
                value={searchMap.lowest_employees}
                error={errorEmployees}
            />
            <Typography
                gutterBottom
                sx={{
                  fontSize: "12.25px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
            >
              to
            </Typography>
            <TextField
                id="employee-high"
                label="Max Employees"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleEmployeeCountHighest}
                value={searchMap.highest_employees}
                error={errorEmployees}
            />
          </Grid>
        </Grid>
        <Grid item xs={5} sx={{ fontSize: "4vw", textAlign: "center" }}>
          <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>
            Estimated Revenue
          </h3>
          <Grid item xs={12} display="Flex" justifyContent="space-evenly">
            <TextField
                id="revenue-low"
                label="Min Revenue"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleRevenueLowest}
                value={searchMap.lowest_estimated_sales_revenue}
                error={errorRevenue}
            />
            <Typography
                gutterBottom
                sx={{
                  fontSize: "12.25px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
            >
              to
            </Typography>
            <TextField
                id="revenue-high"
                label="Max Revenue"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleRevenueHighest}
                value={searchMap.highest_estimated_sales_revenue}
                error={errorRevenue}
            />
          </Grid>
        </Grid>
        <Grid item xs={5} sx={{ fontSize: "4vw", textAlign: "center" }}>
          <h3 style={{ color: "black", margin: "0 0 1rem 0" }}>Search Results Limit</h3>
          <TextField
              id="limit"
              label="Limit"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleLimit}
              value={searchMap.limit}
              sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={5}>
          <button
              onClick={() => getSearchResults(searchMap)}
              className="listingDetails-btn"
              style={{ marginTop: "3rem", width: "100%" }}
              disabled={isSearching}
          >
            {isSearching ? "Searching..." : "Search"}
          </button>
        </Grid>

        {errorMessage && (
            <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        )}
      </Grid>
  );
};

export default Scouts;
