import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Grid } from "@mui/material";
import ListButton from "../Lists/ListButton";
// import Avatar from "@mui/material/Avatar";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LanguageIcon from "@mui/icons-material/Language";
export default function SearchList(props) {
  const [state, setState] = React.useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ state, [anchor]: open });
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const list = (anchor) => (
    <Box
      sx={{
        fontFamily: "Montserrat",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          gap: "1.5rem",
          padding: "3.75rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div style={{ fontSize: "1.5rem", fontWeight: "500" }}>
            {" "}
            {props.biz.name}{" "}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {props.biz.phone && (
            <div className="detail-page-icon">
              <PermPhoneMsgOutlinedIcon />{" "}
              {props.biz.phone ? (
                <span style={{ color: "#0e9384" }}>{props.biz.phone}</span>
              ) : (
                <span>/</span>
              )}
            </div>
          )}
          {(props.biz.street || props.biz.city || props.biz.state) && (
            <div className="detail-page-icon">
              <LocationOnOutlinedIcon />{" "}
              {props.biz.street && `${props.biz.street}, `}
              {props.biz.city && `${props.biz.city}, `}
              {props.biz.state}
            </div>
          )}
          {props.biz.website && (
            <div className="detail-page-icon">
              <LanguageIcon />{" "}
              <a
                href={props.biz.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.biz.website ? (
                  <span style={{ color: "#0e9384" }}>{props.biz.website}</span>
                ) : (
                  <span>/</span>
                )}{" "}
              </a>
            </div>
          )}
          {/*LinkedIn  <div className="detail-page-icon">
            <LinkedInIcon />
            {props.biz.location.display_address}
          </div> */}
        </div>      
       { 
       (props.biz.latitude && props.biz.longtitude) &&
       <iframe
          title={props.biz.name}
          width="95%"
          style={{ border: "0", borderRadius: "10px" }}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={
            `https://www.google.com/maps/embed/v1/place?key=${window.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=` +
            `${props.biz.latitude},${props.biz.longtitude}`
          }
        ></iframe>}
        {(props.biz.estimated_opened_for_business ||
          props.biz.verified_on ||
          props.biz.mailing_address ||
          props.biz.mailing_address_city ||
          props.biz.mailing_address_state ||
          props.biz.mailing_address_postal_code ||
          props.biz.location_email_address ||
          props.biz.primary_contact ||
          props.biz.job_titles) && (
          <div
            id="list"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "500",
                margin: "2rem",
                color: "#0e9384",
              }}
            >
              Detailed Information
            </p>
            <div
              className="detail-list"
              style={{ width: "100%", fontSize: "0.85rem" }}
            >
              {props.biz.estimated_opened_for_business && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Opened for Business Date
                  </div>
                  <span>{props.biz.estimated_opened_for_business}</span>
                </div>
              )}
              {props.biz.verified_on && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Business Date Verified On
                  </div>
                  <span>{props.biz.verified_on}</span>
                </div>
              )}
              {props.biz.mailing_address && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Business Address{" "}
                  </div>
                  <span>{props.biz.mailing_address}</span>
                </div>
              )}
              {props.biz.mailing_address_city && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">Business City </div>
                  <span>{props.biz.mailing_address_city}</span>
                </div>
              )}
              {props.biz.mailing_address_state && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">Business State </div>
                  <span>{props.biz.mailing_address_state}</span>
                </div>
              )}
              {props.biz.mailing_address_postal_code && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Business Postcode{" "}
                  </div>
                  <span>{props.biz.mailing_address_postal_code}</span>
                </div>
              )}
              {props.biz.location_email_address && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">Email Address</div>
                  <span>{props.biz.location_email_address}</span>
                </div>
              )}
              {props.biz.primary_contact && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Primary Contact Name
                  </div>
                  <span>
                    {props.biz.primary_contact.first_name}{" "}
                    {props.biz.primary_contact.last_name}
                  </span>
                </div>
              )}
              {props.biz.job_titles && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    {" "}
                    Primary Contact Title
                  </div>
                  <span>{props.biz.primary_contact.job_titles}</span>
                </div>
              )}
            </div>
            {(props.biz.location_employee_count ||
              props.biz.estimated_location_employee_count ||
              props.biz.estimated_corporate_employee_count ||
              props.biz.estimated_corporate_sales_revenue ||
              props.biz.corporate_sales_revenue) && (
              <div
                className="detail-list"
                style={{ width: "100%", fontSize: "0.8rem" }}
              >
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    margin: "2rem",
                    color: "#0e9384",
                    textAlign: "center",
                  }}
                >
                  Financial Information
                </p>
                {props.biz.location_employee_count && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">Employee Count</div>
                    <span>{props.biz.location_employee_count.toLocaleString('en-US')}</span>
                  </div>
                )}
                {props.biz.estimated_location_employee_count && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">
                      Estimated Location Employee Count
                    </div>
                    <span>{props.biz.estimated_location_employee_count.toLocaleString('en-US')}</span>
                  </div>
                )}
                {props.biz.estimated_corporate_employee_count && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">
                      Estimated Corporate Employee Count
                    </div>
                    <span>{props.biz.estimated_corporate_employee_count.toLocaleString('en-US')}</span>
                  </div>
                )}
                {props.biz.estimated_corporate_sales_revenue && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">
                      Estimated Corporate Sales Revenue
                    </div>
                    <span>{moneyFormatter.format(props.biz.estimated_corporate_sales_revenue).replace(".00", "")}</span>
                  </div>
                )}

                {props.biz.corporate_sales_revenue && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">
                      Reported Corporate Sales Revenue
                    </div>
                    <span>{moneyFormatter.format(props.biz.corporate_sales_revenue).replace(".00", "")}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {/*<div id="list">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "1.5rem", fontStyle: "bold" }}>
              Financial Information
            </p>
          </div>
          <div className="detail-list">
            <div className="detail-list-item">
              <div className="detail-list-item-title">
                Location Employee Count
              </div>
              <div>{props.biz.estimated_corporate_employee_count}asdf</div>
            </div>
            <div className="detail-list-item">
              <div className="detail-list-item-title">
                Corporate Employee Count
              </div>
              <div>{props.biz.estimated_corporate_employee_count}asdf</div>
            </div>
            <div className="detail-list-item">
              <div className="detail-list-item-title">
                Corporate Sales Revenue
              </div>
              <div>{props.biz.estimated_corporate_sales_revenue}asdf</div>
            </div>
          </div>
          </div>*/}
      </div>
    </Box>
  );
  return (
    <div style={{ width: "90%", margin: "2rem 0 1rem 4rem" }}>
      <React.Fragment key="right">
        {/* <div onClick={toggleDrawer("right", true)}> */}
        <Grid item xs={10} sx={{ display: "flex", paddingBottom: "16px" }}>
          {/* <Grid item xs={1}>
            <Avatar
              alt={props.biz.alias}
              src={props.biz.image_url}
              sx={{ width: 56, height: 56 }}
            />
          </Grid> */}
          <Grid item xs={4}>
            <Grid item xs={12}>
              <span className="text">{props.biz.name}</span>
            </Grid>
            <Grid item xs={12}>
              <span className="text">
                {props.biz.street
                  ? props.biz.street
                  : "" + " " + props.biz.city + " " + props.biz.state}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item xs={12}>
              <a
                className="deal-scout-link"
                href={props.biz.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                Website
              </a>
            </Grid>
            <Grid item xs={12}>
              <span className="text">{props.biz.phone}</span>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <div onClick={toggleDrawer("right", true)}>
              <span className="deal-scout-span-link">More Details</span>
            </div>
          </Grid>
          {props.saveButtonState && (
            <Grid item xs={2}>
              <ListButton
                existsInList={props.existsInList}
                handleAddRemoveFromList={props.handleAddRemoveFromList}
                biz={props.biz}
              />
            </Grid>
          )}
        </Grid>
        {/* </div> */}
        <Drawer
          PaperProps={{
            sx: { width: "32.5rem" },
          }}
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
