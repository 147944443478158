import { React, useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import NavBar from '../../components/NavBar/NavBar'
import { useLocation, useNavigate } from 'react-router-dom';
import { retrieveUserAttributes, retrieveUserProfile } from '../../services/UserManagementService';
import { searchListings } from '../../services/ListingManagementService';
import Listing from '../../components/Listing/Listing';
import { SnackBar } from '../../components/SnackBar/SnackBar';
import Footer from '../../components/Footer/Footer';
import loadingGif from '../../assets/Rocket.gif';
import Filter from '../../components/Filter/Filter';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from '@mui/icons-material/Payments';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SortIcon from '@mui/icons-material/Sort';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Industries } from '../../helperData/ListingInfoData';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { constructOpenSearchRequest, shortNumber } from './HelperFunctions';
import '../../pages/Landing/App.scss'
import WelcomeModal from './Welcome';
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
import SourceIcon from '@mui/icons-material/Source';

const sortByList = [
  "Keywords",
  "Newest",
  "Oldest",
  "Highest Price",
  "Lowest Price",
  "Highest Cash Flow",
  "Lowest Cash Flow",
  "Highest Revenue",
  "Lowest Revenue",
];

const drawerWidth = 275;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme, drawOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(drawOpen && {
    justifyContent: 'flex-end',
  }),
  ...(!drawOpen && {
    justifyContent: 'center',
  }),
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'drawOpen' })(
  ({ theme, drawOpen }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(drawOpen && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!drawOpen && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    '@media(max-Width: 1023px)' : {
      display: "none"
  }
  }),
);

function BrowseListings(props) {
  // state management
  const [listings, setListings] = useState([]);  // this will hold total result set from db
  const [totalHits, setTotalHits] = useState(0);
  const [displayRange, setDisplayRange] = useState(20) // this will hold the limit of items to display starting at 20 and grow in increments of 20 each time 'load more' is clicked
  const [displayLoadMore, setDisplayLoadMore] = useState(false);
  const [userProfileData, setUserProfileData] = useState();
  const [userAttributes, setUserAttributes] = useState();
  const [userFavorites, setUserFavorites] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [filterMap, setFilterMap] =  useState((localStorage.getItem("filterSettings")) ? JSON.parse(localStorage.getItem("filterSettings")) : {
    "listing_status": "available",
    "search_phrase" : "",
    "location": "",
    "industries": {},
    "lowest_price": 0,
    "highest_price": 30000000,
    "lowest_cash_flow": 0,
    "highest_cash_flow": 30000000,
    "lowest_revenue": 0,
    "highest_revenue": 30000000,
    "sort_by" : "Keywords",
    "from" : 0,
    "aggregated" : true,
    "direct" : true
  })
  const [drawOpen, setDrawOpen] = useState(true);
  const navigate = useNavigate()

  const handleDrawer = () => setDrawOpen(!drawOpen);
  const handlePriceUpdate = (event, newPrice) => {
    setFilterMap({...filterMap, "lowest_price" : newPrice[0], "highest_price": newPrice[1]});
  };
  const handleCashFlowUpdate = (event, newCashFlow) => {
    setFilterMap({...filterMap, "lowest_cash_flow" : newCashFlow[0], "highest_cash_flow": newCashFlow[1]});
  };
  const handleRevenueUpdate = (event, newRevenue) => {
    setFilterMap({...filterMap, "lowest_revenue" : newRevenue[0], "highest_revenue": newRevenue[1]});
  };

  const handleIndustries = (industry) => {
    if(industry in filterMap.industries){
      delete filterMap.industries[industry];
      setFilterMap({...filterMap})
    }else{
      let industryMap = filterMap.industries;
      industryMap[industry] = true;
      setFilterMap({...filterMap, "industries": industryMap});
    }
  } 

  const handleSource = (source) => {
    setFilterMap({...filterMap, [source] : !filterMap[source]});
  }

  const loadMore = () => {
    const incrementedRange = displayRange + 20;
    if(incrementedRange >= listings.length && (listings.length + filterMap.from) >= totalHits){
      setDisplayRange(listings.length);
      setDisplayLoadMore(false);
    }else if(incrementedRange >= listings.length && (listings.length + filterMap.from) < totalHits){ //if we can retrieve more records
      setDisplayRange(20);
      setFilterMap({...filterMap, "from" : listings.length});
      getFilteredListings({"from" : listings.length});
    }else{
      setDisplayRange(incrementedRange);
    }
  }

  //find any featured listings in the results and choose one at random to show on top of page
  const setListingsWithFeatured = (listingsSet) => {

    if(listingsSet.length){
      
      let featuredListingsFound = []; // list of indexes where featured listings appear
      
      for(let x = 0; x < listingsSet.length; x++){
        if("isFeatured" in listingsSet[x]._source && listingsSet[x]._source.isFeatured === "Y"){
          featuredListingsFound.push(x);
        }
      }

      let chosenFeaturedListing;
      let listingPlaceholder;
      
      if(featuredListingsFound.length > 1){
        chosenFeaturedListing = featuredListingsFound[Math.floor(Math.random() * featuredListingsFound.length)]; //index of featured listing
        listingPlaceholder = listingsSet[0];
        listingsSet[0] = listingsSet[chosenFeaturedListing];
        listingsSet[chosenFeaturedListing] = listingPlaceholder;
      }else if(featuredListingsFound.length === 1){
        chosenFeaturedListing = featuredListingsFound[0]; //index of featured listing
        listingPlaceholder = listingsSet[0];
        listingsSet[0] = listingsSet[chosenFeaturedListing];
        listingsSet[chosenFeaturedListing] = listingPlaceholder;
      }
    }
    setListings(listingsSet)
  }

  const getFilteredListings = async(searchObj={"from": 0}) => {
    setIsLoading(true);
    setDisplayLoadMore(false);
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      const gtagParamMap = {
        button: "search",
      }
      if(userProfileData){
        gtagParamMap.user_email =  userProfileData.email
      }
      window.gtag("event", "button_click", gtagParamMap);
    }
    let submissionMap = {};
    submissionMap = filterMap;

    if(searchObj.from > 0){ submissionMap.from = searchObj.from; }
    let results;
    if (
      userProfileData && userProfileData?.['is-broker'] === "Y" &&
      userProfileData?.subscriptions?.['deal-scout']?.status !== 'active' &&
      userProfileData?.subscriptions?.['deal-scout']?.status !== 'trialing'
    ){
      results = await searchListings(constructOpenSearchRequest(submissionMap, userProfileData.email));
    }else{
      results = await searchListings(constructOpenSearchRequest(submissionMap));
    }
    
    localStorage.setItem("filterSettings", JSON.stringify(submissionMap));

    if(!results.hits){
      setErrorMessage(results);
    }else{
      setListingsWithFeatured(results.hits.hits);
      if(results.hits.total.value === 10000){
        setTotalHits(shortNumber(results.hits.total.value) + ' +')
      }else{
        setTotalHits(shortNumber(results.hits.total.value))
      }
      if(results.hits.hits.length > 20){setDisplayLoadMore(true);}
    }
    setIsLoading(false);
  }

  const getUserAttributes = async() => {
    retrieveUserAttributes().then((response) => {
      if(response.errorMessage){
        setErrorMessage(response.errorMessage);
      }else{
        setUserAttributes(response.result)
      }
    });
  }

  const getUserFavorites = async() => {
    await retrieveUserProfile().then((response) => {
      if(response.errorMessage){
        setErrorMessage(response.errorMessage);
      }else{
        setUserFavorites(prev => (Array.isArray(response?.favorites) ? response.favorites : prev));
        setUserProfileData(response);
      }
    });
  }
  const location = useLocation();

  useEffect(() => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }

    //if the user is logged in...
    if(localStorage.getItem("authorized")){
      
      if(!userAttributes){
        getUserAttributes();
      }
      
      if(!userFavorites && !userFavorites.length && userAttributes && !userProfileData){
        getUserFavorites();
      }

      // redirect the user if they are not subscribed
      if (
        userProfileData && userProfileData?.['is-broker'] === "N" &&
        userProfileData?.subscriptions?.['deal-scout']?.status !== 'active' &&
        userProfileData?.subscriptions?.['deal-scout']?.status !== 'trialing'
        ) {
          navigate('/deal-scout/checkout')
      }

    }else {
      navigate('/login')
    }

    if(userAttributes && userProfileData && !listings.length){
      getFilteredListings();
    }

  },[localStorage.getItem("authorized"), location, userAttributes, userProfileData]);

  return (
    
    <Box sx={{display:"flex", height:"100%"}}>
      <SetPageTitle title="Businesses For Sale" />
        {
          userProfileData &&
          <WelcomeModal openWelcomeModal={location.state === 'N'} isBroker={userProfileData['is-broker']} userEmail={userProfileData.email}
          />}
        <Drawer id='filter-drawer' variant="permanent" drawOpen={drawOpen}>
          <DrawerHeader drawOpen={drawOpen}>
            { 
              drawOpen &&
              <div style={{textAlign:"center"}}>
                <button onClick={getFilteredListings} className='listingDetails-btn'>Apply Filters</button>
              </div>
            }
            <IconButton onClick={handleDrawer} sx={{marginLeft: drawOpen ? "10%" : "0"}}>
              {!drawOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem key="Price" disablePadding sx={{ display: 'block' }}>
              <ListItemButton 
                  onClick={handleDrawer}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Price" sx={{ opacity: drawOpen ? 1 : 0 }} />
              </ListItemButton>
              {
                drawOpen && 
                <Grid item sx={{textAlign:"center"}}>
                    <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold", paddingTop:'1px'}}>
                        {"Price: $" + filterMap.lowest_price.toLocaleString('en-US') + " - $" + filterMap.highest_price.toLocaleString('en-US')}
                    </Typography>
                    <Slider
                        max={30000000}
                        min={0}
                        step={100000}
                        value={[filterMap.lowest_price, filterMap.highest_price]}
                        onChange={handlePriceUpdate}
                        sx={{color:"#0e9384", width:"85%"}}
                        size="small"
                    />
                </Grid>
                }
            </ListItem>
            <Divider />
            <ListItem key="Cash Flow" disablePadding sx={{ display: 'block' }}>
              <ListItemButton 
                  onClick={handleDrawer}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                <PointOfSaleIcon />
                </ListItemIcon>
                <ListItemText primary="Cash Flow" sx={{ opacity: drawOpen ? 1 : 0 }} />
              </ListItemButton>
              {
                drawOpen && 
                <Grid item sx={{textAlign:"center"}}>
                    <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold", paddingTop:'1px'}}>
                        {"Cash Flow: $" + filterMap.lowest_cash_flow.toLocaleString('en-US') + " - $" + filterMap.highest_cash_flow.toLocaleString('en-US')}
                    </Typography>
                    <Slider
                        max={30000000}
                        min={0}
                        step={100000}
                        value={[filterMap.lowest_cash_flow, filterMap.highest_cash_flow]}
                        onChange={handleCashFlowUpdate}
                        sx={{color:"#0e9384", width:"85%"}}
                        size="small"
                    />
                </Grid>
                }
            </ListItem>
            <Divider />
            <ListItem key="Revenue" disablePadding sx={{ display: 'block' }}>
              <ListItemButton 
                  onClick={handleDrawer}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                <PaymentsIcon />
                </ListItemIcon>
                <ListItemText primary="Revenue" sx={{ opacity: drawOpen ? 1 : 0 }} />
              </ListItemButton>
              {
                drawOpen && 
                <Grid item sx={{textAlign:"center"}}>
                    <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold", paddingTop:'1px'}}>
                        {"Revenue: $" + filterMap.lowest_revenue.toLocaleString('en-US') + " - $" + filterMap.highest_revenue.toLocaleString('en-US')}
                    </Typography>
                    <Slider
                        max={30000000}
                        min={0}
                        step={100000}
                        value={[filterMap.lowest_revenue, filterMap.highest_revenue]}
                        onChange={handleRevenueUpdate}
                        sx={{color:"#0e9384", width:"85%"}}
                        size="small"
                    />
                </Grid>
                }
            </ListItem>
            <Divider />
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton 
                    onClick={handleDrawer}
                    sx={{
                      minHeight: 48,
                      justifyContent: drawOpen ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                  <SortIcon />
                </ListItemIcon>
                <ListItemText primary="Sort By" sx={{ opacity: drawOpen ? 1 : 0 }} />
              </ListItemButton>
              {
                drawOpen && 
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Keywords"
                    name="radio-buttons-group"
                    onChange={(event) => setFilterMap({...filterMap, "sort_by" : event.target.value})}
                    value={filterMap.sort_by}
                  >
                    {
                      sortByList.map((sortByItem) => {
                        return(
                          <FormControlLabel key={sortByItem} sx={{
                            '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize:".75vw"},
                            margin:"0px"
                          }} value={sortByItem} control={<Radio size='small'/>} label={sortByItem} />    
                        )
                      })
                    }
                  </RadioGroup>
                </FormControl>
              }
              
            </ListItem>
            <Divider />
            <ListItem key="Deal Source" disablePadding sx={{ display: 'block' }}>
              <ListItemButton 
                  onClick={handleDrawer}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                <SourceIcon
                  sx={{
                    minWidth: 0,
                    mr: drawOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                <StorefrontIcon />
                </SourceIcon>
                <ListItemText primary="Deal Source" sx={{ opacity: drawOpen ? 1 : 0 }} />
              </ListItemButton>
              {
                drawOpen && 
                <Grid item sx={{textAlign:"center"}}>
                     <FormGroup>
                     <FormControlLabel 
                            key="aggregated"
                            control={<Checkbox size='small' onChange={() => handleSource("aggregated")}/>} 
                            label="Aggregated"
                            checked={filterMap['aggregated']}
                            sx={
                              { 
                                '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize:".75vw"},
                                margin: "0"
                              }
                            }
                      />
                       <FormControlLabel 
                            key="direct"
                            control={<Checkbox size='small' onChange={() => handleSource("direct")}/>} 
                            label="Direct"
                            checked={filterMap['direct']}
                            sx={
                              { 
                                '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize:".75vw"},
                                margin: "0"
                              }
                            }
                      />
                    </FormGroup>
                </Grid>
                }
            </ListItem>
            <Divider />
            <ListItem key="Industry" disablePadding sx={{ display: 'block' }}>
              <ListItemButton 
                  onClick={handleDrawer}
                  sx={{
                    minHeight: 48,
                    justifyContent: drawOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                <StorefrontIcon />
                </ListItemIcon>
                <ListItemText primary="Industry" sx={{ opacity: drawOpen ? 1 : 0 }} />
              </ListItemButton>
              {
                drawOpen && 
                <Grid item sx={{textAlign:"center"}}>
                     <FormGroup>
                      {
                        Industries.map((industry) => {
                          return(
                            <FormControlLabel 
                            key={industry}
                            control={<Checkbox size='small' onChange={() => handleIndustries(industry)}/>} 
                            label={industry}
                            checked={industry in filterMap.industries}
                            sx={
                              { 
                                '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize:".75vw"},
                                margin: "0"
                              }
                            }
                            />
                          )
                        })
                      }
                    </FormGroup>
                </Grid>
                }
            </ListItem>
          </List>
        </Drawer>
        <Grid container direction="row" justifyContent="center" marginTop="8px" display="flow-root">
          <Grid item xs={12}>
          <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
          </Grid>
          <Grid item xs={12}>
            <div id="key-word-search">
            <Filter filterMap={filterMap} setFilterMap={setFilterMap} getFilteredListings={getFilteredListings} numberOfListings={totalHits}/>
            </div>
          </Grid>
          {
            isLoading && 
            <Grid item sx={{textAlign:"center"}}>
              <img alt='loading...' src={loadingGif}/>
            </Grid>
          }
          { listings && !isLoading && userFavorites &&
            listings.slice(0,displayRange).map((listing) => {
              return(
                <Listing listing={listing._source} key={listing._id} userAttributes={userAttributes} indexOfFavorite={userFavorites.indexOf(listing._id)} setUserFavorites={setUserFavorites} setErrorMessage={setErrorMessage}/>
              )
            })
          }
          {
            displayLoadMore && !isLoading &&
            <Grid item xs={12} sx={{textAlign:"center"}}>
              <div id="hello-world">
                <button variant="contained" onClick={loadMore} className='listingDetails-btn' style={{width:"83.5%"}}>Load More Listings</button>
              </div>
            </Grid>
          }
          {
            listings.length > 0 && !isLoading &&
            <Footer/>
          }
          {
            listings.length === 0 && !isLoading &&
            <p style={{textAlign:"center", height:"100%"}}>Didnt find any businesses ... Try again using broader filters</p>
          }
        </Grid>
        {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
      </Box>
  );
}

export default BrowseListings;
